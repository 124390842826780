////////////////////////////////////////////////////////////////////////////////
//
// Store site module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";

const state = {
    sites: [],
    isLoading: false,
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    siteByName: state => (name) => state.sites.find(site => site.name === name)
};

////////////////////////////////////////////////////////////////////////////////

const actions = {
    async [Action.site.load](context) {
        try {
            context.commit('setLoading', true)
            const result = await ApiService.site.all()
            if (Array.isArray(result.data.data)) {
                context.commit(Mutation.site.set, result.data.data)
            } else {
                context.commit(Mutation.site.set, [])
            }
            context.commit('setLoading', false)
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            context.commit('setLoading', false)
            return Promise.reject(error)
        }
    },

    async [Action.site.delete](context, site) {
        try {
            await ApiService.site.del(site.name)
            await this.dispatch(Action.site.load)
            context.commit(Mutation.notify, {message: "Account deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.site.add](context, document) {
        try {
            const result = await ApiService.site.add({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.site.save](context, data) {
        try {
            await ApiService.site.save(data.name, data.data)
            context.commit(Mutation.notify, {message: "Site saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.site.set](state, sites) {
        state.sites = sites;
    },
    setLoading(state, loading) {
      state.isLoading = loading
    }
}

////////////////////////////////////////////////////////////////////////////////

const app = {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
export default app