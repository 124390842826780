////////////////////////////////////////////////////////////////////////////////
//
// Kiosk API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const entity = {
    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    load: () => ApiService.getT('config'),

    /**
     * Save the config data
     * @return {Promise<AxiosResponse<any>>}
     */
    save: (data) => {
        const putData = { operator: { $set: data } }
        return ApiService.putT('config', putData)
    }
}

export default entity;