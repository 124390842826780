////////////////////////////////////////////////////////////////////////////////
//
// Account handling service like rights etc..
//
////////////////////////////////////////////////////////////////////////////////


const AccountService = {

    /**
     * Available account subtypes
     */
    subtypes: {
        superadmin: 'sadmin',
        admin: 'admin',
        operator: 'operator',
        infra: 'infra',
        developer: 'developer'
    },

    /**
     * Default rights
     */
    defaultRights: {
        landlord: false,       // -- has landlord rights
        accounts: true,        // -- can view account
        accountsEdit: true,    // -- can edit accounts
    },

    /**
     * Build array of account subtype objects to use in dropdown
     * @param withSuperadmin
     * @returns array of account subtypes
     */
    typesForDropdown: function(withSuperadmin = false) {
        let types = [
            { value: 'admin',     text: 'Admin' },
            { value: 'developer', text: 'Developer' },
            { value: 'operator',  text: 'Operator' },
            { value: 'infra',     text: 'Infra' },
        ];
        if(withSuperadmin) {
            types = [{ value: 'sadmin',    text: 'Superadmin' }].concat(types)
        }
        return types;
    },

    /**
     * Get rights for a given subtype
     * @param subtype the subtype as string
     * @returns rights object
     */
    getRights: function(subtype) {
        let rights = {};
        let key;
        for (key in this.defaultRights) { rights[key] = this.defaultRights[key] }
        switch (subtype) {
            // -- superadmin
            case this.subtypes.superadmin:
                rights.landlord = true;
                break;
        }
        return rights;
    }
}

export default AccountService;