<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <b-form id="entity_form" role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header title="Edit Entity object attributes">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>
              <b-row>

                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" disabled />
                </b-col>

                <b-col md="6">
                  <base-input label="Description" v-model="form.description" />
                </b-col>

              </b-row>

              <b-row>

                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Network Info <span class="font-weight-light">Optional</span></label>

                    <base-input :rules="{json: true}">
                      <textarea class="form-control font-mono" rows="6" v-model="form.networkinfo" />
                    </base-input>

                    <file-loader
                        initialLabel="Load JSON file"
                        accept=".json"
                        :maxSize="100000"
                        :parse-json="false"
                        :content.sync="form.networkinfo" />
                  </fieldset>
                </b-col>

                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Global Info <span class="font-weight-light">Optional</span></label>

                    <base-input :rules="{json: true}">
                      <textarea class="form-control font-mono" rows="6" v-model="form.globalinfo" />
                    </base-input>

                    <file-loader
                        initialLabel="Load JSON file"
                        accept=".json"
                        :maxSize="100000"
                        :parse-json="false"
                        :content.sync="form.globalinfo" />

                  </fieldset>
                </b-col>

              </b-row>

            </b-card-body>
          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>

<script>

import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {MapEntityConfig} from "@/services/map.service";
import FileLoader from "@/views/components/FileLoader";

export default {
  name: "EntityEdit",
  components: {
    FileLoader
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        globalinfo: undefined,
        networkinfo: undefined,
      },
      isLoading: true
    };
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.home})
    },
    onSubmit() {
      this.isLoading = true
      const model = MapEntityConfig.formToModel(this.form)
      this.$store.dispatch(Action.entity.saveConfig, model)
        .then(() => {
          this.$router.push({name: Routes.home})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    },
  },
  mounted() {
    this.$store.dispatch(Action.entity.loadConfig)
        .then(() => {
          this.isLoading = false
          this.form = MapEntityConfig.modelToForm(this.$store.state.entity.entityConfig, this.form)
        })
  }
}
</script>

