////////////////////////////////////////////////////////////////////////////////
//
// Mapping of form data to API object
//
////////////////////////////////////////////////////////////////////////////////

/**
 * Map form data to location object
 * @param formData
 * @return null|object
 */
const mapLocation = function(formData) {
    if (formData.site) {
        let locationObject = {
            site: formData.site,
        }
        locationObject.gps_coordinates = [Number(formData.latitude), Number(formData.longitude)]
        if (formData.floor) {
            locationObject.floor = formData.floor
        }
        return locationObject
    }
    return null
}

/**
 * Map service site
 */
const MapServiceSite = {

    /**
     * Map form data to object
     * @param formData
     * @return {{}}
     */
    formToModel: (formData) => {
        let model = {}
        model.name = formData.name
        model.description = formData.description

        if  (formData.networkinfo) {
            model.networkinfo = JSON.parse(formData.networkinfo)
        }
        if  (formData.globalinfo) {
            model.globalinfo = JSON.parse(formData.globalinfo)
        }

        model.address = formData.address
        model.gps_coordinates = [Number(formData.latitude), Number(formData.longitude)]

        return model
    },


    modelToForm(model, formModel) {
        formModel.name = model.name
        formModel.description = model.description
        formModel.address = model.address

        formModel.networkinfo = JSON.stringify(model.networkinfo,null, 2)
        formModel.globalinfo = JSON.stringify(model.globalinfo,null, 2)

        formModel.latitude = model.gps_coordinates.coordinates[0]
        formModel.longitude = model.gps_coordinates.coordinates[1]

        return formModel
    }

}


const MapEntityConfig = {

    formToModel(formData) {
        let model = {}
        model.description = formData.description
        if  (formData.networkinfo) {
            model.networkinfo = JSON.parse(formData.networkinfo)
        }
        if  (formData.globalinfo) {
            model.globalinfo = JSON.parse(formData.globalinfo)
        }
        return model
    },

    modelToForm(model, formModel) {
        formModel.name = model.name
        formModel.description = model.description
        formModel.networkinfo = JSON.stringify(model.networkinfo,null, 2)
        formModel.globalinfo = JSON.stringify(model.globalinfo,null, 2)

        return formModel
    }

}

/**
 * Mapping dispenser
 */
const MapServiceDispenser = {

    /**
     * Map form data to object
     * @param formData
     * @return {{}}
     */
    formToModel: (formData) => {
        let model = {}
        model.name = formData.name
        model.description = formData.description
        model.capacity = Number(formData.capacity)
        if (formData.kiosk) {
            model.kiosk = formData.kiosk
        }
        const location = mapLocation(formData)
        if (location) {
            model.location = location
        }
        if (formData.networkInfo) {
            model.networkinfo = JSON.parse(formData.networkInfo)
        }
        if (formData.config) {
            model.configuration = JSON.parse(formData.config)
        }
        if (formData.cert) {
            model.X509_certificate = formData.cert
        }
        return model
    },


    modelToForm: (model, formModel) => {
        formModel.name = model.name
        formModel.description = model.description
        formModel.cert = model.X509_certificate

        formModel.networkInfo = JSON.stringify(model.networkinfo,null, 2)
        formModel.config = JSON.stringify(model.configuration,null, 2)

        if (model.location) {
            formModel.site = model.location.site
            formModel.floor = model.location.floor
            formModel.latitude = model.location.gps_coordinates[0]
            formModel.longitude = model.location.gps_coordinates[1]
        }
        formModel.kiosk = model.kiosk
        formModel.capacity = model.capacity
        return formModel
    }
}


/**
 * Map service device
 */
const MapServiceDevice = {

    /**
     * Map form data to object
     * @param formData
     * @return {{}}
     */
    formToModel: (formData) => {
        let model = {}

        model.name = formData.name
        model.description = formData.description
        model.manufacturing_type = formData.manufacturing_type
        model.version_id = formData.version_id
        model.mac_1 = formData.lora_deveui
        model.mac_2 = formData.lte_imsi
        model.X509_certificate = formData.cert

        return model
    },


    modelToForm: (model, formModel) => {
        formModel.name = model.name
        formModel.description = model.description
        formModel.manufacturing_type = model.manufacturing_type
        formModel.lora_deveui = model.mac_1
        formModel.lte_imsi = model.mac_2
        formModel.version_id = model.version_id
        formModel.cert = model.X509_certificate
        return formModel
    }
}


/**
 * Map service dataview
 */
const MapServiceDataview = {

    formToModel: (formData) => {
        let model = {}
        model.name = formData.name
        model.description = formData.description
        model.version = formData.version
        model.owner = formData.owner
        model.authorized_accounts = formData.authorized_accounts
        if (formData.content.length === 0) {
            model.content = {
                name: model.name,
                displayName: model.description
            }
        } else {
            model.content = formData.content
        }
        return model;
    },

    modelToForm: (model, formModel) => {
        formModel.name = model.name
        formModel.description = model.description
        formModel.version = model.version
        formModel.owner = model.owner
        formModel.authorized_accounts = model.authorized_accounts
        formModel.content = model.content
        return formModel
    }

}


/**
 * Map service kiosk
 */
const MapServiceKiosk = {
    formToModel: (formData) => {
        let model = {}
        model.name = formData.name
        model.description = formData.description
        const location = mapLocation(formData)
        if (location) {
            model.location = location
        }
        return model
    },
    modelToForm: (model, formModel) => {
        formModel.name = model.name
        formModel.description = model.description
        if (model.location) {
            formModel.site = model.location.site
            formModel.floor = model.location.floor
            formModel.latitude = model.location.gps_coordinates[0]
            formModel.longitude = model.location.gps_coordinates[1]
        }
        return formModel
    }
}

export { MapServiceDispenser, MapServiceDevice, MapServiceDataview, MapServiceKiosk, MapServiceSite, MapEntityConfig }