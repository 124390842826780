////////////////////////////////////////////////////////////////////////////////
//
// App API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const app = {

    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    all: () => ApiService.getT('dataview'),

    /**
     * Add the
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    add: (data) => {
        const postData = { document: data }
        return ApiService.postT('dataview', postData)
    },

    /**
     * Delete account with name
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    del: (name) => ApiService.deleteT(`dataview/${name}`),

    /**
     * Save App with data
     * @param name dataview resource name
     * @param data { "description": "", "name": "", ... }
     */
    save: (name, data) => {
        const dataCopy = { ...data }
        if (name === dataCopy.name) {
            delete dataCopy.name
        }
        const putData = { operator: { $set: dataCopy } }
        return ApiService.putT(`dataview/${name}`, putData)
    }
}

export default app;