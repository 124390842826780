<template>
  <div>
    <base-header class="pb-6" />

    <b-container fluid class="mt--6 max-width">

      <b-card no-body>

        <card-header title="Accounts">
          <base-button size="sm" @click="onNewAccount">Add Account</base-button>
        </card-header>

        <el-table header-row-class-name="thead-light" :data='accounts'>

          <el-table-column label="Username" prop="name" min-width="140px" class-name="font-weight-bold" sortable  />
          <el-table-column label="Type" min-width="140px" sortable prop="subtype"/>
          <el-table-column label="E-mail" min-width="140px" prop="email" />

          <!-- ACTIONS -->
          <el-table-column min-width="70px" align="right">
            <div slot-scope="{row}" class="table-actions">

              <base-button
                  @click.native="onEdit(row)"
                  class="remove btn-link" type="" size="sm" icon
              >
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button
                  @click.native="onDelete(row)"
                  class="remove btn-link"
                  type="danger" size="sm" icon
                  :disabled="row.name === account.name"
              >
                <i class="text-white fas fa-trash"></i>
              </base-button>

            </div>
          </el-table-column>


        </el-table>
      </b-card>

    </b-container>
  </div>

</template>

<!-- ###################################################################### -->

<script>

import { Table, TableColumn, Tooltip } from 'element-ui'
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import CardHeader from "@/views/components/CardHeader";
import {mapState} from "vuex";
import {confirmDelete} from "@/helper";


export default {
  name: "AccountList",
  components: {
    CardHeader,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    onNewAccount() {
      this.$router.push({name: Routes.accountNew});
    },
    onEdit(row) {
      this.$router.push({name: Routes.accountEdit, params: { name: row.name }});
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.account.delete, row);
        }
      });
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.account.accounts,
      isLoading: state => state.general.loading,
      account: state => state.auth.account
    })
  },
  // ---------
  mounted() {
    this.$store.dispatch(Action.account.load)
  }
}
</script>