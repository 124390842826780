////////////////////////////////////////////////////////////////////////////////
//
// Device API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const device = {

    // -- the endpoint
    endpoint: 'device',

    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    all: () => ApiService.getT(device.endpoint),

    /**
     * Add a device with data
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    add: (data) => ApiService.postT(device.endpoint, data),

    /**
     * Delete account with name
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    del: (name) => ApiService.deleteT(`${device.endpoint}/${name}`),


    /**
     * Save Site with data
     * @param name dataview resource name
     * @param data { "description": "", "name": "", ... }
     */
    save: (name, data) => {
        const dataCopy = { ...data }
        if (name === dataCopy.name) {
            delete dataCopy.name
        }
        const putData = { operator: { $set: dataCopy } }
        return ApiService.putT(`${device.endpoint}/${name}`, putData)
    }
}

export default device;