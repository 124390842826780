////////////////////////////////////////////////////////////////////////////////
//
// Store device module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";

const state = {
    devices: []
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    deviceByName: state => (name) => state.devices.find(device => device.name === name)
};

////////////////////////////////////////////////////////////////////////////////

const actions = {
    async [Action.device.load](context) {
        try {
            const result = await ApiService.device.all()
            if (Array.isArray(result.data.data)) {
                context.commit(Mutation.device.set, result.data.data)
            } else {
                context.commit(Mutation.device.set, [])
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.device.add](context, document) {
        try {
            const result = await ApiService.device.add({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.device.save](context, data) {
        try {
            await ApiService.device.save(data.name, data.data)
            context.commit(Mutation.notify, {message: "Device saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },


    async [Action.device.delete](context, device) {
        try {
            await ApiService.device.del(device.name)
            await this.dispatch(Action.device.load)
            context.commit(Mutation.notify, {message: "Device deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },


    async [Action.device.allocate](context, data) {
        try {
            const result = await ApiService.kioskManager.allocateDevice(data.kiosk, data.payload)
            if (result.data.httpResponseCode === 202 && result.data.errorInfo) {
                context.commit(Mutation.notify, {message: result.data.errorInfo.userMessage, type: 'warning'})
            } else {
                context.commit(Mutation.notify, {message: 'Success', type: 'success'})
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.device.return](context, data) {
        try {
            const result = await ApiService.kioskManager.returnDevice(data.device, data.kiosk)
            if (result.data.httpResponseCode === 202) {
                context.commit(Mutation.notify, {message: result.data.errorInfo.userMessage, type: 'warning'})
            } else {
                context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.device.set](state, devices) {
        state.devices = devices;
    }
}

////////////////////////////////////////////////////////////////////////////////

const devices = {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
export default devices