<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header :title="title">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Allocate</base-button>
            </card-header>

            <b-card-body>


              <b-row>
                <b-col>
                  <base-input label="New User ID" name="name" v-model="form.user" :rules="{required: true, apiname: true}" />
                </b-col>
              </b-row>


              <b-row>


                <b-col>
                  <label class="form-control-label">Select dataviews to add</label>

                  <hr>
                  <div v-for="dataview in dataviews" :key="dataview.name">

                    <b-form-checkbox v-model="form.selectedDataviews" :value="dataview.name" class="mb-3">
                      {{ dataview.name }}
                    </b-form-checkbox>

                    <base-input :rules="{json: form.selectedDataviews.includes(dataview.name)}"  v-show="form.selectedDataviews.includes(dataview.name)">
                      <textarea class="form-control font-mono" rows="4" v-model="form.dataviewValue[dataview.name]" />
                    </base-input>

                    <hr>

                  </div>
                </b-col>

                <b-col>
                  <label class="form-control-label">Network Info <span class="font-weight-light">Optional</span></label>

                  <base-input :rules="{json: true}">
                    <textarea class="form-control font-mono" rows="6" v-model="form.networkInfo" />
                  </base-input>

                  <file-loader
                      class="mb-1"
                      initialLabel="Select JSON file"
                      accept=".json"
                      :maxSize="100000"
                      :content.sync="form.networkInfo" />
                </b-col>

              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>
      </b-card>
    </b-container>

  </div>
</template>

<script>
import {Action} from "@/store/actions";
import {mapGetters, mapState} from "vuex";
import {Routes} from "@/router";
import FileLoader from "@/views/components/FileLoader";

export default {
  name: "DeviceAllocate",
  components: {
    FileLoader
  },
  data() {
    return {
      form: {
        user: '',
        networkInfo: null,
        selectedDataviews: [],
        dataviewValue: {},
      },
      activeDevice: '',
      lastDispenser: '',
      isLoading: false,
    };
  },
  computed: {
    dataviews() {
      return this.apps
    },
    title() {
      return "Allocate Device " + this.activeDevice
    },
    ...mapGetters(['deviceByName']),
    ...mapState({
      apps: state => state.app.apps,
    }),
  },
  methods: {
    onSubmit() {

      let model = this.mapFormToAllocationData()
      let kiosk = this.$store.getters.dispenserByName(this.lastDispenser).kiosk
      if(!kiosk || kiosk === "") {
        this.$store.dispatch(Action.notify, {message: 'Device has no kiosk information.'})
        return
      }
      this.isLoading = true
      this.$store.dispatch(Action.device.allocate, {kiosk: kiosk, payload: model})
          .then(() => {
            this.$router.push({name: Routes.deviceList})
          }).catch(error => {
            this.isLoading = false
            console.error(error)
          })
    },
    onCancel() {
      this.$router.push({name: Routes.deviceList});
    },

    mapFormToAllocationData() {
      let that = this
      let model = {}
      model.visitor = this.form.user
      model.device = this.activeDevice
      if (this.form.networkInfo) {
        model.network = JSON.parse(this.form.networkInfo)
      }
      model.apps = {}
      this.form.selectedDataviews.forEach(function(dataview) {
        let valueRaw = that.form.dataviewValue[dataview]
        let value = (valueRaw && valueRaw !== "") ? valueRaw : "{}"
        model.apps[dataview] = JSON.parse(value)
      })
      return model
    }
  },
  mounted() {
    this.$store.dispatch(Action.app.load)
    this.$store.dispatch(Action.dispenser.load)
    const name = this.$route.params.name
    if(!name) { this.$router.back() }
    const device = this.deviceByName(name)
    if(!device || device.last_dispenser.dispenser === "") { this.$router.back() }
    this.activeDevice = name
    this.lastDispenser = device.last_dispenser.dispenser
  }
}
</script>