<!-- ######################################################################
#
# Authentication Layout
#
###################################################################### -->

<template>
  <div>
    <notifications></notifications>
    <base-nav
        type="light"
        :transparent="true"
        menu-classes="justify-content-end"
        class="navbar-horizontal navbar-main"
        expand="lg"
    >

      <div slot="brand" class="navbar-wrapper mr-auto">
        <b-navbar-brand to="/">
          <img src="/img/logo2.png">
        </b-navbar-brand>
      </div>

      <template>
        <div class="navbar-collapse-header">
          <b-row>
            <b-col cols="6" class="collapse-brand">
              <router-link to="/">
                <img src="/img/logo2.png">
              </router-link>
            </b-col>

            <hr class="d-lg-none">

            <b-col cols="6" class="collapse-close">
              <button type="button" class="navbar-toggler" @click="showMenu = false">
                <span></span>
                <span></span>
              </button>
            </b-col>
          </b-row>
        </div>

        <b-navbar-nav  class="align-items-lg-center ml-lg-auto">

          <a href="https://www.infrafon.com" target="_blank" class="btn btn-dark">
            <span class="px-3">Support</span>
          </a>

        </b-navbar-nav>

      </template>

    </base-nav>

    <div class="main-content">
      <slot/>
    </div>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {mapState} from "vuex";

export default {
  name: "LayoutAuth",

  ////////////////////////////////////////////////////////////////////////////////

  data() {
    return {
      showMenu: false,
    }
  },

  ////////////////////////////////////////////////////////////////////////////////

  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add('bg-white');
    },
    removeBackgroundColor() {
      document.body.classList.remove('bg-white');
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor()
      }
    }
  },

  ////////////////////////////////////////////////////////////////////////////////

  computed: {
    ...mapState({
      general: state => state.general
    }),
  },

  ////////////////////////////////////////////////////////////////////////////////

  beforeDestroy() {
    this.removeBackgroundColor();
  },

  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground()
      }
    }
  }
}
</script>