////////////////////////////////////////////////////////////////////////////////
//
// Dispenser API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const dispenser = {
    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    all: () => ApiService.getT('dispenser'),

    /**
     * Add a account with data
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    add: (data) => ApiService.postT('dispenser', data),

    /**
     * Delete account with name
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    del: (name) => ApiService.deleteT(`dispenser/${name}`),

    /**
     * Save Site with data
     * @param name dataview resource name
     * @param data { "description": "", "name": "", ... }
     */
    save: (name, data) => {
        const dataCopy = { ...data }
        if (name === dataCopy.name) {
            delete dataCopy.name
        }
        const putData = { operator: { $set: dataCopy } }
        return ApiService.putT(`dispenser/${name}`, putData)
    },

    /**
     * Put a device into the dispenser
     * @param device
     * @param dispenser
     */
    putDevice: (dispenser, device) => {
        return ApiService.postT(`dispenser/${dispenser}/put/${device}`)

    }
}

export default dispenser;