////////////////////////////////////////////////////////////////////////////////
//
// Token Service
//
////////////////////////////////////////////////////////////////////////////////

const ID_TOKEN_KEY = "id_token";
const ID_USERNAME = "id_username";
const ID_ENTITY = "id_entity";
const ID_TOKEN_REFRESH = "id_token_refresh";

////////////////////////////////////////////////////////////////////////////////

const TokenService = {
    token: () => window.localStorage.getItem(ID_TOKEN_KEY),
    tokenSave: (token) => { window.localStorage.setItem(ID_TOKEN_KEY, token) },
    tokenDelete: () => { window.localStorage.removeItem(ID_TOKEN_KEY) },

    username: () => window.localStorage.getItem(ID_USERNAME),
    usernameSave: (username) => { window.localStorage.setItem(ID_USERNAME, username) },
    usernameDelete: () => { localStorage.removeItem(ID_USERNAME) },

    // -- active entity
    entity: () => window.localStorage.getItem(ID_ENTITY),
    entitySave: (entity) => { window.localStorage.setItem(ID_ENTITY, entity) },
    entityDelete: () => { localStorage.removeItem(ID_ENTITY) },

    // -- refresh
    refresh: () => window.localStorage.getItem(ID_TOKEN_REFRESH),
    refreshSave: (token) => { localStorage.setItem(ID_TOKEN_REFRESH, token) },
    refreshDelete: () => { localStorage.removeItem(ID_TOKEN_REFRESH) }
}

////////////////////////////////////////////////////////////////////////////////

export { TokenService };
