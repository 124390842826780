<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header title="Edit Dataview">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>

              <b-row>
                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" :rules="{required: true, apiname: true}"/>
                </b-col>
                <b-col md="6">
                  <base-input label="Description" v-model="form.description" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input label="Version" name="version" v-model="form.version" :rules="{required: true}" />
                </b-col>
                <b-col md="6">
                  <label class="form-control-label">Owner</label>
                  <account-select :selected.sync="form.owner" :add-empty="false" :filter-subtype="['admin', 'developer']" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Authorized Accounts <span class="font-weight-light">(Owner is always authorized)</span></label>
                    <account-select-list :selected.sync="form.authorized_accounts" :filter-subtype="['admin', 'developer']" />
                  </fieldset>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <label class="form-control-label">Update JSON</label>
                  <file-loader
                      initialLabel="Select dataview json file"
                      accept=".json"
                      :maxSize="1000000"
                      :parse-json="true"
                      :content.sync="form.content" />
                  <div class="pt-2 pb-2">or</div>
                  <base-button type="success" block @click="onAppBuilder">Open Dataview Designer to edit dataview</base-button>
                </b-col>
                <b-col md="6">
                  <label class="form-control-label">Download JSON</label>
                  <base-button type="secondary" block @click="onDownload">Download</base-button>
                </b-col>
              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Routes} from "@/router";
import {mapGetters} from "vuex";
import {Action} from "@/store/actions";
import {download} from "@/helper";
import FileLoader from "@/views/components/FileLoader";
import AccountSelect from "@/views/components/AccountSelect";
import AccountSelectList from "@/views/components/AccountSelectList";
import {MapServiceDataview} from "@/services/map.service";

export default {
  name: "AppEdit",
  components: {
    FileLoader,
    AccountSelect,
    AccountSelectList
  },
  computed: {
    ...mapGetters(['appByName']),
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        version: '',
        owner: null,
        content: '',
        authorized_accounts: [],
      },
      activeDataview: null,
      isLoading: false,
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      const model = MapServiceDataview.formToModel(this.form)
      this.$store.dispatch(Action.app.save, {name: this.activeDataview, model: model})
          .then(() => { this.$router.push({name: Routes.appList}) })
          .catch(error => {
            console.error(error)
            this.isLoading = false
          })
    },
    onCancel() {
      this.$router.push({name: Routes.appList})
    },
    onAppBuilder() {
      this.$router.push({name: Routes.appBuilder, params: { name: this.activeDataview }});
    },
    onDownload() {
      download(`${this.activeDataview}.json`, JSON.stringify(this.form.content))
    },
  },
  beforeMount() {
    const name = this.$route.params.name
    if(!name) { this.$router.back(); return; }
    const app = this.appByName(name)
    if(!app) { this.$router.back(); return; }
    this.activeDataview = app.name
    this.form = MapServiceDataview.modelToForm(app, this.form)
  }
}


</script>

