////////////////////////////////////////////////////////////////////////////////
//
// Edity api
//
////////////////////////////////////////////////////////////////////////////////

import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";
import {Mutation} from "@/store/mutations";

const state = {
    entityConfig: null
}

////////////////////////////////////////////////////////////////////////////////

const actions = {
    async [Action.entity.loadConfig](context) {
        try {
            const result = await ApiService.entity.load()
            context.commit(Mutation.entityConfig.set, result.data.data)
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.entity.saveConfig](context, config) {
        try {
            await ApiService.entity.save(config)
            context.commit(Mutation.notify, {message: "Success", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.entityConfig.set](state, entityConfig) {
        state.entityConfig = entityConfig;
    }
}

////////////////////////////////////////////////////////////////////////////////

const entity = {
    state: state,
    actions: actions,
    mutations: mutations,

}
export default entity