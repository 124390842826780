<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">


          <card-header title="Create Site">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
            </card-header>

            <b-card-body>
              <b-row>
                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Description" name="description" v-model="form.description"/>
                </b-col>
              </b-row>

              <b-row>

                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Network Info <span class="font-weight-light">Optional</span></label>

                    <base-input :rules="{json: true}">
                      <textarea class="form-control font-mono" rows="6" v-model="form.networkinfo" />
                    </base-input>

                    <file-loader
                        initialLabel="Load JSON file"
                        accept=".json"
                        :maxSize="100000"
                        :content.sync="form.networkinfo" />
                  </fieldset>
                </b-col>

                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Global Info <span class="font-weight-light">Optional</span></label>

                    <base-input :rules="{json: true}">
                      <textarea class="form-control font-mono" rows="6" v-model="form.globalinfo" />
                    </base-input>

                    <file-loader
                        initialLabel="Load JSON file"
                        accept=".json"
                        :maxSize="100000"
                        :parse-json="true"
                        :content.sync="form.globalinfo" />
                    </fieldset>
                </b-col>

              </b-row>

              <b-row>
                <b-col md="3">
                  <base-input label="Address" name="street" placeholder="Street" v-model="form.address.street" />
                </b-col>
                <b-col md="3" class="no-label">
                  <base-input name="number" placeholder="Number" v-model="form.address.number" :rules="{ numeric: true }"/>
                </b-col>

                <b-col md="3">
                  <base-input label="GPS" name="latitude" placeholder="Latitude" v-model="form.latitude" :rules="{ latitude: true, required: true }" />
                </b-col>
                <b-col md="3" class="no-label">
                  <base-input label="" name="longitude" placeholder="Longitude" v-model="form.longitude" :rules="{ longitude: true, required: true }" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <base-input name="postalcode" placeholder="Postalcode" v-model="form.address.postalcode" />
                </b-col>
                <b-col md="3">
                  <base-input name="locality" placeholder="Locality/town" v-model="form.address.city" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <base-input name="region" placeholder="Region" v-model="form.address.region" />
                </b-col>
                <b-col md="3">
                  <base-input name="country" placeholder="Country" v-model="form.address.country" />
                </b-col>
              </b-row>

            </b-card-body>


          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>
import {Routes} from "@/router";
import CardHeader from "@/views/components/CardHeader";
import {Action} from "@/store/actions";
import FileLoader from "@/views/components/FileLoader";
import {MapServiceSite} from "@/services/map.service";

export default {
  name: "SiteNew",
  components: {
    CardHeader,
    FileLoader
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        networkinfo: undefined,
        globalinfo: undefined,
        address: {
          street: '',
          number: undefined,
          postalcode: '',
          city: '',
          region: '',
          country: '',
        },
        latitude: '',
        longitude: '',
      },
      isLoading: false,
    };
  },
  // ---------------------
  computed: {
    networkInfoText() {
      return JSON.stringify(this.form.networkinfo,null, 2)
    },
    globalInfoText() {
      return JSON.stringify(this.form.globalinfo,null, 2)
    }
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.siteList});
    },
    onSubmit() {
      this.isLoading = true
      this.$store.dispatch(Action.site.add, MapServiceSite.formToModel(this.form))
        .then(() => {
          this.$router.push({name: Routes.siteList})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    }
  }
}
</script>

<!-- ###################################################################### -->

<style scoped>
</style>