<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>
        <card-header title="Devices">
          <b-form-input v-model="searchQuery" placeholder="Search devices by name..." size="md" class="mb-3" />
          <!-- Entity Dropdown -->
          <b-form-select v-model="selectedEntity" :options="entityOptions" size="sm" class="mb-3">
            <b-form-select-option :value="null">Please select an entity</b-form-select-option>
          </b-form-select>
        </card-header>

        <el-table header-row-class-name="thead-light" empty-text="No devices available" :data="filteredDevices">
          <el-table-column width="200" class-name="font-weight-bold" label="Name" sortable prop="name" />
          <el-table-column label="Entity" sortable prop="entity" />
          <el-table-column label="Description" prop="description" />
          <el-table-column label="Battery" sortable prop="battery" />
          <!-- ACTIONS -->
          <!-- <el-table-column width="140px" align="right">
            <div class="table-actions">

              <base-button class="remove btn-link" type="" size="sm" icon>
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>
            </div>
          </el-table-column> -->
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>



<!-- ###################################################################### -->

<script>

import { mapState } from "vuex";
import { Action } from "@/store/actions";
import { Routes } from "@/router";
import { Table, TableColumn, Tooltip } from "element-ui";
import CardHeader from "@/views/components/CardHeader";
import { confirmDelete } from "@/helper";

export default {
  name: "sdevices",
  components: {
    CardHeader,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      searchQuery: '',
      selectedEntity: null,  // Holds the currently selected entity
    };
  },
  methods: {
    onNewEntity() {
      this.$router.push({ name: Routes.entityNew })
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.landlord.deleteEntity, row);
        }
      });
    },
    loadEntities() {
      this.$store.dispatch(Action.landlord.loadEntities)
    }
  },
  computed: {
    ...mapState({
      entities: state => state.landlord.entities,
      loading: state => state.general.loading,
    }),
    entityOptions() {
      return this.entities.map(entity => ({ value: entity.name, text: entity.name }));
    },
    allDevices() {
      return this.entities.map(entity => entity.device.map(device => ({ ...device, entity: entity.name }))).flat();
    },
    filteredDevices() {
      let filtered = this.allDevices;
      if (this.searchQuery) {
        filtered = filtered.filter(device => device.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      if (this.selectedEntity) {
        filtered = filtered.filter(device => device.entity === this.selectedEntity);
      }
      return filtered;
    }
  },
  mounted() {
    this.loadEntities();

  }
}
</script>