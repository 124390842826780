////////////////////////////////////////////////////////////////////////////////
//
// Kiosk Manager API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const kioskManager = {

    /**
     * Allocate device
     */
    allocateDevice: (kiosk, data) => {
        return ApiService.postKioskManager(`${kiosk}/allocate`, data)
    },


    /**
     * Return device
     * @param {string} device
     * @param {string} dispenser
     */
    returnDevice: (device, kiosk) => {
        return ApiService.postKioskManager(`${kiosk}/return`, {device: device})
    }


}

export default kioskManager;