<template>
  <div>
    <base-header class="pb-6" />

    <b-container fluid class="mt--6 max-width">

      <b-card no-body>

        <card-header title="Entities">
            <base-button size="sm" @click="onNewEntity">Add Entity</base-button>
        </card-header>

        <el-table header-row-class-name="thead-light" empty-text="No entites available" :data='entities'>

          <el-table-column width="200" class-name="font-weight-bold" label="Name" sortable prop="name" />
          <el-table-column label="Description" sortable prop="description"/>

          <el-table-column width="100" align="right">
            <div slot-scope="{row}" class="table-actions">
              <base-button @click.native="onDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>
            </div>
          </el-table-column>

        </el-table>

      </b-card>

    </b-container>

  </div>
</template>

<!-- ###################################################################### -->

<script>

import {mapState} from "vuex";
import {Action} from "@/store/actions";
import {Routes} from "@/router";
import {Table, TableColumn, Tooltip} from "element-ui";
import CardHeader from "@/views/components/CardHeader";
import {confirmDelete} from "@/helper";

export default {
  name: "EntityList",
  components: {
    CardHeader,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    onNewEntity() {
      this.$router.push({name: Routes.entityNew})
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.landlord.deleteEntity, row);
        }
      });
    },
    loadEntities() {
      this.$store.dispatch(Action.landlord.loadEntities)
    }
  },
  computed: {
    ...mapState({
      entities: state => state.landlord.entities,
      
      loading: state => state.general.loading,
    }),

  },
  mounted() {
    this.loadEntities();

  }
}
</script>
