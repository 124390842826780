////////////////////////////////////////////////////////////////////////////////
//
// Store auth module
//
////////////////////////////////////////////////////////////////////////////////

import { TokenService } from "@/services/token.service";
import AccountService from "@/services/account.service";
import ApiService from "@/services/api.service";
import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";

////////////////////////////////////////////////////////////////////////////////

const state = {
    authError: null,
    account: null,
    rights: AccountService.defaultRights,
    isAuthenticated: !!TokenService.token(),
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    username: (state) => state.account ? state.account.name : '',
    entity: (state) => state.account? state.account._entity : '',
    usernameShort: (state) => {
        if (state.account) {
            return state.account.name ? state.account.name.substr(0, 2).toLocaleUpperCase() : ""
        } else {
            return ""
        }
    },
    isAuthenticated: (state) => state.isAuthenticated
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.auth.error](state, error) {
        state.authError = error;
    },

    [Mutation.auth.set](state, data) {
        state.error = null;
        state.isAuthenticated = true;
        TokenService.tokenSave(data.access_token)
        TokenService.refreshSave(data.refresh_token)
        TokenService.usernameSave(data.logged_in_as)
        TokenService.entitySave(data.entity)
    },

    [Mutation.auth.setAccount](state, data) {
        state.account = data
        state.rights = AccountService.getRights(data.subtype)
    },

    [Mutation.auth.delete](state) {
        state.isAuthenticated = false;
        state.error = null;
        TokenService.tokenDelete()
        TokenService.refreshDelete()
        TokenService.usernameDelete()
        TokenService.entityDelete()
    },

}

////////////////////////////////////////////////////////////////////////////////

const actions = {

    async [Action.auth.login](context, credentials) {
        context.commit(Mutation.auth.error, undefined)
        try {
            const login = await ApiService.auth.login(credentials)
            context.commit(Mutation.auth.set, login.data.data)
            context.commit(Mutation.entity, login.data.data.entity)
            ApiService.setHeader();
            const account = await ApiService.auth.getAccount(TokenService.username(), TokenService.entity())
            context.commit(Mutation.auth.setAccount, account.data.data)
        } catch (error) {
            context.commit(Mutation.auth.delete);
            if ([401, 403].includes(error.errorInfo.errorCode)) {
                context.commit(Mutation.auth.error, "Wrong password")
            } else {
                context.commit(Mutation.auth.error, error.errorInfo.userMessage)
            }
            return Promise.reject(error)
        }
    },

    [Action.auth.logout](context) {
        context.commit(Mutation.auth.delete);
    },

    async [Action.auth.check](context) {
        if (!TokenService.token() || !TokenService.entity()) {
            context.commit(Mutation.auth.delete);
            return;
        }
        context.commit(Mutation.entity, TokenService.entity())
        ApiService.setHeader();
        if (state.account) { return }
        try {
            const account = await ApiService.auth.getAccount(TokenService.username(), TokenService.entity())
            context.commit(Mutation.auth.setAccount, account.data.data)
            if (!state.entity && TokenService.entity()) {
                context.commit(Mutation.entity, TokenService.entity());
            }
        } catch (error) {
            context.commit(Mutation.auth.error, "Error loading user data")
            context.commit(Mutation.auth.delete);
        }
    },


}


////////////////////////////////////////////////////////////////////////////////

const auth = {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}
export default auth
