////////////////////////////////////////////////////////////////////////////////
//
// Auth API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const auth = {
    /**
     * Login with credentials
     * @param credentials
     * @returns {Promise<AxiosResponse<any>>}
     */
    login: (credentials) => ApiService.post("login", credentials),

    /**
     * Get account object for username
     * @param username
     * @param entity
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAccount: (username, entity) => {
        if(entity === '__landlord__entity') {
            return ApiService.get(`account/${username}`)
        } else {
            return ApiService.getT(`account/${username}`)
        }
    },
}

export default auth;