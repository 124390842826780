<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form id="entity_form" role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header title="Create Dataview">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
            </card-header>

            <b-card-body>

              <b-row>
                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Description" v-model="form.description" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input label="Version" name="version" v-model="form.version" :rules="{required: true}" />
                </b-col>
                <b-col md="6">
                  <label class="form-control-label">Owner</label>
                  <account-select :selected.sync="form.owner" :add-empty="false" :filter-subtype="['admin', 'developer']" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Authorized Accounts <span class="font-weight-light">(Owner is always authorized)</span></label>
                    <account-select-list :selected.sync="form.authorized_accounts" :filter-subtype="['admin', 'developer']" />
                  </fieldset>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <label class="form-control-label">Content</label>
                  <file-loader
                      initialLabel="Select dataview json file"
                      accept=".json"
                      :maxSize="1000000"
                      :parse-json="true"
                      :content.sync="form.content" />
                </b-col>
              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>
import {Action} from "@/store/actions";
import {Routes} from "@/router";
import {mapState} from "vuex";
import FileLoader from "@/views/components/FileLoader";
import AccountSelect from "@/views/components/AccountSelect";
import AccountSelectList from "@/views/components/AccountSelectList";
import {MapServiceDataview} from "@/services/map.service";

export default {
  name: "AppNew",
  components: {
    FileLoader,
    AccountSelect,
    AccountSelectList
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        version: '',
        owner: null,
        content: '',
        authorized_accounts: [],
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      account: state => state.auth.account,
    }),
  },
  methods: {
    onCancel() {
      this.$router.back()
    },
    onSubmit() {
      this.isLoading = true
      this.$store.dispatch(Action.app.add, MapServiceDataview.formToModel(this.form))
          .then(() => {
            this.$router.push({name: Routes.appList})
          })
          .catch(error => {
            console.error(error)
            this.isLoading = false;
          })
    },
  },
  beforeMount() {
    this.form.owner = this.account.name
  }

}
</script>

