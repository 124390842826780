<!-- ######################################################################
#
# Superuser Form
#
###################################################################### -->

<template>
  <div>
    <base-header class="pb-6" />

    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form id="entity_form" role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header :title="headline">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>
              <!-- username email ####################################################### -->
              <b-row>
                <b-col md="6" class="justify-content-center">
                  <base-input label="Username" name="name"
                              v-model="model.name"
                              :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="E-mail" placeholder="" name="E-mail" v-model="model.email" :rules="{required: true, email: true}"/>
                </b-col>
              </b-row>
              <!-- password button ####################################################### -->
              <b-row>
                <b-col md="6" class="mb-4">
                  <label class="form-control-label">Password</label>
                  <base-button type="secondary" block @click="showModalPassword=true">Change Password</base-button>
                </b-col>
              </b-row>
              <!-- account type + entity ####################################################### -->
              <b-row v-if="model.subtype !== 'sadmin'">
                <b-col md="6">
                  <label class="form-control-label">Account Type</label>
                  <b-form-select
                      class="form-control"
                      v-model="model.subtype"
                      :options="accountTypes" />
                </b-col>
                <b-col md="6">
                  <base-input label="Entity" name="entity" v-model="entity" disabled />
                </b-col>
              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>

        <hr>

        <b-card-header>
          <PermanentToken
              :entity="entity"
              :account="editAccount"
              :is-s-admin="true"
          />
        </b-card-header>

      </b-card>
    </b-container>

    <!-- MODAL PW FORM ###################################################################### -->
    <modal-password
        :show.sync="showModalPassword"
        :on-save="onSubmitPassword"
    />

  </div>
</template>

<!-- ###################################################################### -->

<script>
import CardHeader from "@/views/components/CardHeader";
import {mapGetters, mapState} from "vuex";
import {Action} from "@/store/actions";
import {Routes} from "@/router";
import AccountService from "@/services/account.service";
import ModalPassword from "@/views/components/ModalPassword";
import PermanentToken from "@/views/components/PermanentToken";

export default {
  name: "SAccountNew",
  // -----------------
  data() {
    return {
      editAccount: '',
      passwordConfirmation: '',
      showModalPassword: false,
      entity: '',
      model: {
        name: '',
        email: '',
        subtype: '',
      },
      accountTypes: AccountService.typesForDropdown()
    };
  },
  // -----------------
  components: {
    PermanentToken,
    CardHeader,
    ModalPassword,
  },
  // -----------------
  methods: {
    onCancel() { this.$router.back() },
    onSubmit() {
      this.$store.dispatch(Action.landlord.saveAccount, {name: this.editAccount, data: this.model})
          .then(() => { this.$router.push({name: Routes.saccountList}) })
          .catch(error => { console.error(error) })
    },
    onSubmitPassword(newPassword) {
      this.$store.dispatch(Action.landlord.saveAccount, {name: this.editAccount, data: { password: newPassword }})
          .then(() => { this.showModalPassword = false })
          .catch(error => { console.error(error) })

    }
  },
  // -----------------
  computed: {
    ...mapState({
      isLoading: state => state.general.loading,
    }),
    ...mapGetters(['saccountByName']),

    headline: function() {
        return "Edit " + this.editAccount
    },
  },
  // -----------------
  mounted() {
    const name = this.$route.params.name
    if(name) {
      const account = this.saccountByName(name)
      if(!account) {
        this.$router.back()
      } else {
        this.editAccount = name;
        this.model.name = account.name
        this.model.email = account.email
        this.model.subtype = account.subtype
        this.entity = account.entity
      }
    }
  },

}
</script>
