////////////////////////////////////////////////////////////////////////////////
//
// Router
//
////////////////////////////////////////////////////////////////////////////////

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import {Action} from "@/store/actions";

import Login from "@/pages/Login";
import AccountList from "@/pages/account/AccountList";
import AccountForm from "@/pages/account/AccountNew";
import AccountEdit from "@/pages/account/AccountEdit";
import EntityList from "@/pages/entity/EntityList";
import EntityForm from "@/pages/entity/EntityNew";

import SAccountList from "@/pages/saccount/SAccountList";
import SAccountNew from "@/pages/saccount/SAccountNew";
import SAccountEdit from "@/pages/saccount/SAccountEdit";

import SDevicesList from "@/pages/sDevices/sDevicesList";
import SDevicesNew from "@/pages/sDevices/sDevicesNew";
import SDevicesEdit from "@/pages/sDevices/sDevicesEdit";

import AccountSettings from "@/pages/account/AccountSettings";
import AppList from "@/pages/dataview/DataviewList";
import AppEdit from "@/pages/dataview/DataviewEdit";
import AppNew from "@/pages/dataview/DataviewNew";
import AppBuilder from "@/pages/appbuilder/AppBuilder";
import SiteList from "@/pages/sites/SiteList";
import SiteEdit from "@/pages/sites/SiteEdit";
import SiteNew from "@/pages/sites/SiteNew";
import KioskList from "@/pages/kiosk/KioskList";
import KioskNew from "@/pages/kiosk/KioskNew";
import KioskEdit from "@/pages/kiosk/KioskEdit";
import DispenserList from "@/pages/dispenser/DispenserList";
import DispenserNew from "@/pages/dispenser/DispenserNew";
import DispenserEdit from "@/pages/dispenser/DispenserEdit";
import DeviceList from "@/pages/device/DeviceList";
import DeviceEdit from "@/pages/device/DeviceEdit";
import DeviceNew from "@/pages/device/DeviceNew";
import DeviceAllocate from "@/pages/device/DeviceAllocate";
import DeviceDeploy from "@/pages/device/DeviceDeploy";
import EntityEdit from "@/pages/entity/EntityEdit";

////////////////////////////////////////////////////////////////////////////////

const Routes = {
  home: 'home',

  authLogin: 'login',
  saccountList: 'saccountList',
  saccountNew: 'saccountNew',
  saccountEdit: 'saccountEdit',

  accountList: 'accounts',
  accountEdit: 'accountsEdit',
  accountNew: 'accountsNew',
  accountSettings: 'accountSettings',

  entityList: 'entities',
  entityNew: 'entityNew',
  entityEdit: 'entityEdit',

  sDevicesList: 'sDevices',
  sDevicesNew: 'sDevicesNew',
  sDevicesEdit: 'sDevicesEdit',

  

  appList: 'appList',
  appNew: 'appNew',
  appEdit: 'appEdit',
  appBuilder: 'appBuilder',

  siteList: 'siteList',
  siteEdit: 'siteEdit',
  siteNew: 'siteNew',

  kioskList: 'kioskList',
  kioskEdit: 'kioskEdit',
  kioskNew: 'kioskNew',

  dispenserList: 'dispenserList',
  dispenserEdit: 'dispenserEdit',
  dispenserNew: 'dispenserNew',

  deviceList: 'deviceList',
  deviceEdit: 'deviceEdit',
  deviceNew: 'deviceNew',
  deviceAllocate: 'deviceAllocate',
  deviceDeploy: 'deviceDeploy',
}
export { Routes }

////////////////////////////////////////////////////////////////////////////////

Vue.use(VueRouter)

////////////////////////////////////////////////////////////////////////////////

let authPages = {
  path: '/auth/login',
  name: Routes.authLogin,
  component: Login,
  meta: {
      public: true,
      onlyWhenLoggedOut: true,
      layout: 'LayoutAuth'
  }
}
////////////////////////////////////////////////////////////////////////////////

let sDevicePages = [{
  path: '/sdevices',
  name: Routes.sDevicesList,
  component: SDevicesList
},{
  path: '/sdevices/new',
  name: Routes.sDevicesNew,
  component: SDevicesNew
},{
  path: '/sdevices/:name',
  name: Routes.sDevicesEdit,
  component: SDevicesEdit
}]
////////////////////////////////////////////////////////////////////////////////

let saccountPages = [{
  path: '/saccounts',
  name: Routes.saccountList,
  component: SAccountList
},{
  path: '/saccounts/new',
  name: Routes.saccountNew,
  component: SAccountNew
},{
  path: '/saccounts/:name',
  name: Routes.saccountEdit,
  component: SAccountEdit
}]

////////////////////////////////////////////////////////////////////////////////

let accountPages = [{
  path: '/account',
  name: Routes.accountList,
  component: AccountList,
}, {
  path: '/account/new',
  name: Routes.accountNew,
  component: AccountForm,
},{
  path: '/account/:name',
  name: Routes.accountEdit,
  component: AccountEdit,
}, {
  path: '/settings',
  name: Routes.accountSettings,
  component: AccountSettings
}]

////////////////////////////////////////////////////////////////////////////////

const entityPages = [{
  path: '/entity',
  name: Routes.entityList,
  component: EntityList
},{
  path: '/entity/new',
  name: Routes.entityNew,
  component: EntityForm
},{
  path: '/entity/config',
  name: Routes.entityEdit,
  component: EntityEdit,
}]

////////////////////////////////////////////////////////////////////////////////

const appPages = [{
  path: '/dataview',
  name: Routes.appList,
  component: AppList
},{
  path: '/dataview/new',
  name: Routes.appNew,
  component: AppNew
},{
  path: '/dataview/:name',
  name: Routes.appEdit,
  component: AppEdit
}]

////////////////////////////////////////////////////////////////////////////////

const appBuilderPages = [{
  path: '/appbuilder/:name',
  name: Routes.appBuilder,
  component: AppBuilder
}]

////////////////////////////////////////////////////////////////////////////////

const sitePages = [{
  path: '/site',
  name: Routes.siteList,
  component: SiteList
},{
  path: '/site/new',
  name: Routes.siteNew,
  component: SiteNew
},{
  path: '/site/:name',
  name: Routes.siteEdit,
  component: SiteEdit
}]
////////////////////////////////////////////////////////////////////////////////

const kioskPages = [{
  path: '/kiosk',
  name: Routes.kioskList,
  component: KioskList
},{
  path: '/kiosk/new',
  name: Routes.kioskNew,
  component: KioskNew
},{
  path: '/kiosk/:name',
  name: Routes.kioskEdit,
  component: KioskEdit
}]

////////////////////////////////////////////////////////////////////////////////

const dispenserPages = [{
  path: '/dispenser',
  name: Routes.dispenserList,
  component: DispenserList
},{
  path: '/dispenser/new',
  name: Routes.dispenserNew,
  component: DispenserNew
},{
  path: '/dispenser/:name',
  name: Routes.dispenserEdit,
  component: DispenserEdit
}]

////////////////////////////////////////////////////////////////////////////////

const devicePages = [{
  path: '/device',
  name: Routes.deviceList,
  component: DeviceList
},{
  path: '/device/new',
  name: Routes.deviceNew,
  component: DeviceNew
},{
  path: '/device/:name',
  name: Routes.deviceEdit,
  component: DeviceEdit
},{
  path: '/device/:name/allocate',
  name: Routes.deviceAllocate,
  component: DeviceAllocate
},{
  path: '/device/:name/deploy',
  name: Routes.deviceDeploy,
  component: DeviceDeploy
}]

////////////////////////////////////////////////////////////////////////////////

let mainPages = {
  path: '/',
  name: Routes.home,
  redirect: '/account'
}

////////////////////////////////////////////////////////////////////////////////

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    mainPages,
    authPages,
    ...entityPages,
    ...saccountPages,
    ...sDevicePages,
    ...accountPages,
    ...appPages,
    ...appBuilderPages,
    ...sitePages,
    ...kioskPages,
    ...dispenserPages,
    ...devicePages,
  ],
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
});

////////////////////////////////////////////////////////////////////////////////

router.beforeEach((to, from, next) =>
    Promise.all([store.dispatch(Action.auth.check)]).then(next)
);

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const isLoggedIn = store.getters.isAuthenticated
  
  if (!isPublic && !isLoggedIn) {
    return next({
      path: '/auth/login',
      query: { redirect: to.fullPath }
    });
  }

  if (isLoggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next();
});

////////////////////////////////////////////////////////////////////////////////

export default router
