<!-- ######################################################################
#
# Main Header
#
###################################################################### -->

<template>
  <div class="header" :class="{[`bg-${type}`]: type}">
    <b-container fluid>
      <div class="header-body">
        <b-row align-v="center" class="py-4">
          <b-col lg="6" cols="7">
            <slot></slot>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>
  export default {
    name: 'base-header',
    props: {
      type: {
        type: String,
        default: 'dark',
        description: 'Header background type'
      }
    }
  }
</script>

<!-- ###################################################################### -->

<style lang="scss">
.header {
  padding-top: 5rem;
}
</style>
