import LayoutMain from "@/views/layouts/LayoutMain";
import LayoutAuth from "@/views/layouts/LayoutAuth";
import BaseNav from "@/views/components/theme/navbar/BaseNav";
import BaseButton from '@/views/components/theme/BaseButton.vue';
import BaseHeader from '@/views/components/theme/BaseHeader';
import BaseInput from "@/views/components/theme/inputs/BaseInput";
import FileInput from "@/views/components/theme/inputs/FileInput";
import BaseDropdown from "@/views/components/theme/BaseDropdown";
import BaseAlert from "@/views/components/theme/BaseAlert";
import Modal from "@/views/components/theme/Modal";
import Card from "@/views/components/theme/cards/Card";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CardHeader from "@/views/components/CardHeader";

const GlobalComponents = {
    install(Vue) {
        Vue.component(LayoutAuth.name, LayoutAuth);
        Vue.component(LayoutMain.name, LayoutMain);
        Vue.component(BaseNav.name, BaseNav)
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseHeader.name, BaseHeader);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(FileInput.name, FileInput);
        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseAlert.name, BaseAlert);
        Vue.component(Card.name, Card);
        Vue.component(Modal.name, Modal);
        Vue.component(CardHeader.name, CardHeader)
        Vue.component('validation-provider', ValidationProvider)
        Vue.component('validation-observer', ValidationObserver)
    }
}

export default GlobalComponents;