<template>
    <b-form-select v-model="localSelected" v-on:change="selectChange" :options="sitesForSelect" />
</template>


<script>
import {Action} from "@/store/actions";
import {mapState} from "vuex";

export default {
  name: "SitesSelect",
  props: {
    selected: String,
    addEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localSelected: ''
    }
  },
  computed: {
    ...mapState({
      sites: state => state.site.sites
    }),
    sitesForSelect: function() {
      const select = [];
      if (this.addEmpty) {
        select.push({value: null, text: '-'})
      }
      this.sites.forEach((site) => {
        select.push({value: site.name, text: site.name})
      })
      return select
    }
  },
  methods: {
    selectChange: function(siteSelected) {
      this.$emit('update:selected', siteSelected)
    },
    prefill: function() {
      const found = this.sitesForSelect.find(site => site.value === this.localSelected)
      if (this.sitesForSelect.length > 0 && !found) {
        this.localSelected = this.sitesForSelect[0].value
        this.$emit('update:selected', this.localSelected)
      }
    }
  },
  mounted() {
    this.localSelected = this.selected
    this.$store.dispatch(Action.site.load).then(() => {
      this.prefill()
    })
  }
}
</script>

