<!-- ######################################################################
#
# Account Settins View
#
###################################################################### -->

<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header title="Account Settings">
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>
              <!-- Username / E-Mail ###################################################################### -->
              <b-row>
                <b-col md="6">
                  <base-input label="Username" name="name" v-model="account.name" disabled />
                </b-col>
                <b-col md="6">
                  <base-input label="E-mail" name="E-mail" v-model="model.email" :rules="{required: true, email: true}"/>
                </b-col>
              </b-row>
              <!-- change password ####################################################### -->
              <b-row>
                <b-col md="6">
                  <base-input label="Type" name="name" v-model="account.subtype" disabled />
                </b-col>
                <b-col md="6">
                  <label class="form-control-label">Password</label>
                  <base-button type="secondary" block @click="showPassword=true">Change Password</base-button>
                </b-col>
              </b-row>
            </b-card-body>

          </b-form>
        </validation-observer>

        <hr>

        <b-card-header>
          <PermanentToken
              :is-s-admin="account.subtype===accountSubtypes.superadmin"
              :entity="account._entity"
              :account="account.name"
          />
        </b-card-header>

      </b-card>
    </b-container>

    <modal-password :show.sync="showPassword" :on-save="onSubmitPassword"/>

  </div>
</template>

<!-- ###################################################################### -->

<script>

import PermanentToken from "@/views/components/PermanentToken";
import ModalPassword from "@/views/components/ModalPassword";
import AccountService from "@/services/account.service";
import {mapState} from "vuex";
import {Action} from "@/store/actions";

export default {
  name: "AccountSettings",
  // ---------------------
  components: {
    PermanentToken,
    ModalPassword
  },
  // ---------------------
  data() {
    return {
      accountSubtypes: AccountService.subtypes,
      model: {
        email: '',
      },
      showPassword: false,
    }
  },
  // ---------
  methods: {
    onSubmit() {
      if (this.account.subtype === AccountService.subtypes.superadmin) {
        this.onSubmitSuperAdmin()
        return
      }
      this.$store.dispatch(Action.account.save, {name: this.account.name, data: this.model})
          .then(() => { this.account.email = this.model.email })
          .catch(error => { console.error(error) })
    },
    onSubmitSuperAdmin() {
      this.$store.dispatch(Action.landlord.saveAccount, {name: this.account.name, data: this.model})
          .then(() => { this.account.email = this.model.email })
          .catch(error => { console.error(error) })
    },
    onSubmitPassword(newPassword) {
      const action = (this.account.subtype === AccountService.subtypes.superadmin) ? Action.landlord.saveAccount : Action.account.save
      this.$store.dispatch(action, {name: this.account.name, data: { password: newPassword }} )
          .then(() => { this.showPassword = false })
          .catch(error => { console.error(error) })
    },

  },
  // ---------
  computed: {
    ...mapState({
      account: state => state.auth.account,
      isLoading: state => state.isLoading,
    })
  },
  mounted() {
    this.model.email = this.account.email
  }
}
</script>
