<!-- ######################################################################
#
# Login View
#
###################################################################### -->

<template>
  <div>

    <div class="header py-7 py-lg-8 pt-lg-9"></div>

    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">

          <base-alert dismissible type="danger" v-if="error">{{error}}</base-alert>

          <b-card no-body class="bg-transparent border border-light">

            <b-card-header>
              <h1 class="text-center mt-2 mb-3 text-dark pt-3">Welcome</h1>
              <p class="text-center text-dark">Please sign up with<br/>your credentials</p>
              <div class="text-center text-dark">
                <small>For support please contact us at <a href="mailto:support@infrafon.com">support@infrafon.com</a></small>
              </div>
            </b-card-header>

            <b-card-body class="bg-secondary rounded-bottom">

              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" class="mx-3">

                  <base-input alternative
                              class="mb-3"
                              name="Username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-hat-3"
                              placeholder="Username"
                              v-model="model.username"
                              >
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <div class="text-center">
                    <base-button
                        type="dark"
                        native-type="submit"
                        class="my-3"
                        :loading="general.isLoading"
                    >
                      <span class="px-3">Sign in</span>
                    </base-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import { mapState } from "vuex";
import {Routes} from "@/router";
import {Action} from "@/store/actions";

export default {

  name: "Login",

  data() {
    return {
      model: {
        username: '',
        password: '',
      }
    };
  },

  ////////////////////////////////////////////////////////////////////////////////

  methods: {

    onSubmit() {
      this.$store
          .dispatch(Action.auth.login, {name: this.model.username, password: this.model.password})
          .then(() => {
            const next = this.rights.landlord ? Routes.entityList : Routes.accountList
            this.$router.push({name: next})
          })
          .catch(error => { console.error(error) })
      this.password = ''
    }

  },

  ////////////////////////////////////////////////////////////////////////////////

  computed: {
    ...mapState({
      general: state => state.general,
      error: state => state.auth.authError,
      rights: state => state.auth.rights
    }),
  },

}
</script>

