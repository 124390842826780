<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">

      <b-card no-body>
        <card-header title="Devices">
          <base-button size="sm" @click="onNew" disabled>Add Devices</base-button>
          <base-button size="sm" @click="onNew">Add Single Device</base-button>
        </card-header>

        <el-table header-row-class-name="thead-light" empty-text="No devices found" :data='devices'>

          <el-table-column class-name="font-weight-bold" label="Device ID" sortable prop="name" />

          <el-table-column label="Type" sortable prop="manufacturing_type" />

          <el-table-column label="Lora DevEUI" prop="mac_1" width="170px" />

          <el-table-column label="LTE IMSI" prop="mac_2" />

          <el-table-column label="Dispenser" sortable prop="last_dispenser.dispenser" />

          <el-table-column label="User ID" sortable prop="allocation.user" />

          <el-table-column label="State" width="170px" sortable><template v-slot="{row}">
            <span v-html="stateFormatter(row)"></span>
          </template></el-table-column>

          <el-table-column label="Battery" width="100px"><div slot-scope="{row}">
            {{ row.battery }}%
          </div></el-table-column>

          <el-table-column align="right" width="280px">
            <div slot-scope="{row}" class="table-actions">

              <base-button v-if="row.last_dispenser.dispenser && row.state.current !== 'DEP_ALLOCING'" @click.native="onAllocate(row)" type="primary" size="sm" icon>
                Allocate
              </base-button>
              <base-button @click.native="onReturn(row)" type="primary" size="sm" icon>
                Return
              </base-button>

              <base-button @click.native="onEdit(row)" type="" size="sm" icon>
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button @click.native="onDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>

            </div>
          </el-table-column>

        </el-table>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapState} from "vuex";
import {Table, TableColumn} from "element-ui";
import {confirmDelete} from "@/helper";

export default {
  name: "DeviceList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    loadDevice() {
      this.$store.dispatch(Action.device.load)
    },
    onNew() {
      this.$router.push({name: Routes.deviceNew})
    },
    onEdit(row) {
      this.$router.push({name: Routes.deviceEdit, params: { name: row.name }})
    },
    onAllocate(row) {
      this.$router.push({name: Routes.deviceAllocate, params: { name: row.name }})
    },
    onReturn(row) {
      this.$router.push({name: Routes.deviceDeploy, params: { name: row.name }})
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.device.delete, row);
        }
      });
    },
    stateFormatter(value) {
      const state = value.state.current
      if (state === 'UNKNOWN') {
        return 'Unkown'
      } else if (state === 'DEP_ALLOCING') {
        return '<span class="allocated">Allocated</span>'
      } else if (state === 'DEP_DEALLOCING') {
        return '<span class="returned">DEALLOCING</span>'
      } else if (state === 'DEP_UNALLOC') {
        return '<span class="returned">UNALLOC</span>'
      }
      return state
    }
  },
  computed: {
    ...mapState({
      devices: state => state.device.devices
    })
  },
  mounted() {
    this.loadDevice();
  }
}
</script>

<style>
  span.allocated {
    color: #6DD400;
  }
  span.returned {
    color: #E02020;
  }
</style>