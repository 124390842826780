<template>
  <div>
    <b-form-checkbox-group v-model="localSelected">
      <div v-for="account in accountsForList" :key="account.value">
        <b-form-checkbox
            v-model="localSelected"
            v-on:change="selectChange"
            :value="account.value">
          {{ account.text }}
        </b-form-checkbox>
      </div>
    </b-form-checkbox-group>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AccountSelectList",
  props: {
    selected: Array,
    filterSubtype: Array,
  },
  data() {
    return {
      localSelected: []
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.account.accounts,
    }),
    accountsForList: function() {
      const list = [];
      this.accounts.forEach((account) => {
        if ((this.filterSubtype && this.filterSubtype.find(role => account.subtype === role)) || !this.filterSubtype) {
          list.push({value: account.name, text: account.name})
        }
      })
      return list
    }
  },
  methods: {
    selectChange: function (selected) {
      this.$emit('update:selected', selected)
    },
  },
  mounted() {
    this.localSelected = this.selected
  }
}
</script>
