////////////////////////////////////////////////////////////////////////////////
//
// Account API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const account = {
    /**
     * Get account
     * @param name the account username
     * @returns {Promise<AxiosResponse<any>>}
     */
    get: (name) => ApiService.getT(`account/${name}`),

    /**
     * Get permanent token for account
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    token: (name) => ApiService.getT(`account/${name}/permanent-token`),

    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    all: () => ApiService.getT('account'),

    /**
     * Delete account with name
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    del: (name) => ApiService.deleteT(`account/${name}`),

    /**
     * Add a account with data
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    add: (data) => ApiService.postT('account', data),

    /**
     * Save account
     * @param name the account name = login
     * @param data the data
     * @returns {Promise<AxiosResponse<any>>}
     */
    save: (name, data) => {
        if(data.password === "") { delete data.password }
        if(data.entity === "") { delete data.entity }
        const putData = {
            operator: { $set: data }
        }
        return ApiService.putT(`account/${name}`, putData)
    },

}

export default account;
