<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">

            <card-header title="Edit Dispenser">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>
              <b-row>
                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Description" name="description" v-model="form.description"/>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <fieldset>
                    <label class="form-control-label">Sites <span class="font-weight-light">Optional</span></label>
                    <sites-select :selected.sync="form.site"/>
                  </fieldset>
                </b-col>

                <b-col md="6">
                  <fieldset>
                    <label class="form-control-label">Kiosk <span class="font-weight-light">Optional</span></label>
                    <kiosk-select :selected.sync="form.kiosk"/>
                  </fieldset>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col md="6">
                  <label class="form-control-label">GPS Location within site <span class="font-weight-light">Optional and only editable if site is set</span></label>
                </b-col>
                <b-col md="3">
                  <label class="form-control-label">Floor Level</label>
                </b-col>
                <b-col md="3">
                  <label class="form-control-label">Capacity</label>
                </b-col>
              </b-row>

              <b-row>

                <b-col md="3">
                  <base-input
                      name="latitude"
                      placeholder="Latitude"
                      v-model="form.latitude"
                      :rules="{ latitude: !isSiteSelected, required: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>

                <b-col md="3">
                  <base-input
                      name="longitude"
                      placeholder="Longitude"
                      v-model="form.longitude"
                      :rules="{ longitude: !isSiteSelected, required: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>

                <b-col md="3">
                  <base-input
                      name="floor"
                      placeholder="Int"
                      v-model="form.floor"
                      :rules="{ numeric: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>

                <b-col md="3">
                  <base-input
                      name="capacity"
                      v-model="form.capacity"
                      :rules="{ required: true, numeric: true, min_value: 1, max_value: 1000 }"
                  />
                </b-col>

              </b-row>

              <b-row>

                <b-col md="6">
                  <label class="form-control-label">Configuration <span class="font-weight-light">Optional</span></label>

                  <base-input :rules="{json: true}">
                    <textarea class="form-control font-mono" rows="6" v-model="form.config" />
                  </base-input>

                  <file-loader
                      initialLabel="Select JSON file"
                      accept=".json"
                      :maxSize="1000000"
                      :content.sync="form.config" />
                </b-col>

                <b-col md="6">
                  <label class="form-control-label">Network Info <span class="font-weight-light">Optional</span></label>

                  <base-input :rules="{json: true}">
                    <textarea class="form-control font-mono" rows="6" v-model="form.networkInfo" />
                  </base-input>

                  <file-loader
                      class="mb-1"
                      initialLabel="Select JSON file"
                      accept=".json"
                      :maxSize="100000"
                      :content.sync="form.networkInfo" />
                </b-col>
              </b-row>

              <b-row>


                <b-col md="12" class="mt-4">
                  <label class="form-control-label">X509 Certificate <span class="font-weight-light">Optional</span></label>

                  <textarea class="form-control mb-2 font-mono" rows="2" v-model="form.cert" readonly></textarea>

                  <file-loader
                      initialLabel="Select Certificate file"
                      accept=".pem,.pub"
                      :maxSize="100000"
                      :content.sync="form.cert" />
                </b-col>
              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>



<script>
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapGetters} from "vuex";
import SitesSelect from "@/views/components/SitesSelect";
import KioskSelect from "@/views/components/KioskSelect";
import FileLoader from "@/views/components/FileLoader";
import {MapServiceDispenser} from "@/services/map.service";

export default {
  name: "DispenserEdit",
  components: {
    SitesSelect,
    KioskSelect,
    FileLoader
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        site: null,
        kiosk: null,
        latitude: '',
        longitude: '',
        floor: undefined,
        capacity: '',
        wifiap: '',
        networkInfo: undefined,
        config: undefined,
        cert: undefined,
      },
      activeDispenser: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['dispenserByName']),
    isSiteSelected: function() {
      return this.form.site === null
    }
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.dispenserList});
    },
    onSubmit() {
      this.isLoading = true
      const model = MapServiceDispenser.formToModel(this.form)
      this.$store.dispatch(Action.dispenser.save, {name: this.activeDispenser, data: model})
        .then(() => {
          this.$router.push({name: Routes.dispenserList})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    }
  },
  // ---------------------
  beforeMount() {
    const name = this.$route.params.name
    if(!name) { this.$router.back() }
    const dispenser = this.dispenserByName(name)
    if(!dispenser) { this.$router.back() }
    this.activeDispenser = name;
    this.form = MapServiceDispenser.modelToForm(dispenser, this.form)
  }
}
</script>

