<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">


          <card-header title="Create Kiosk">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
            </card-header>

            <b-card-body>
              <b-row>
                <b-col md="6">
                  <base-input label="Name" name="name" v-model="form.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Description" name="description" v-model="form.description"/>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <fieldset class="form-group">
                    <label class="form-control-label">Site <span class="font-weight-light">Optional</span></label>
                    <sites-select :selected.sync="form.site"/>
                  </fieldset>
                </b-col>
              </b-row>

              <b-row>

                <b-col md="3">
                  <base-input
                      label="GPS"
                      name="latitude"
                      placeholder="Latitude"
                      v-model="form.latitude"
                      :rules="{ latitude: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>
                <b-col md="3" class="no-label">
                  <base-input
                      name="longitude"
                      placeholder="Longitude"
                      v-model="form.longitude"
                      :rules="{ longitude: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>

                <b-col md="6">
                  <base-input
                      label="Floor"
                      name="floor"
                      placeholder="Int"
                      v-model="form.floor"
                      :rules="{ numeric: !isSiteSelected }"
                      :disabled="isSiteSelected"
                  />
                </b-col>

              </b-row>

            </b-card-body>


          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import SitesSelect from "@/views/components/SitesSelect";
import {MapServiceKiosk} from "@/services/map.service";

export default {
  name: "KioskNew",
  components: {
    SitesSelect,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        site: '',
        latitude: '',
        longitude: '',
        floor: undefined,
      },
      isLoading: false,
    };
  },
  computed: {
    isSiteSelected: function() {
      return this.form.site === null
    }
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.kioskList});
    },
    onSubmit() {
      this.isLoading = true
      this.fillGPS()
      this.$store.dispatch(Action.kiosk.add, MapServiceKiosk.formToModel(this.form))
        .then(() => {
          this.$router.push({name: Routes.kioskList})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    },
    fillGPS() {
      if (!this.form.site) return;
      const siteObj = this.$store.getters.siteByName(this.form.site)
      if (!this.form.latitude) {
        this.form.latitude = siteObj.gps_coordinates.coordinates[0]
      }
      if (!this.form.longitude) {
        this.form.longitude = siteObj.gps_coordinates.coordinates[1]
      }
    }
  }
}
</script>

