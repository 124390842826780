////////////////////////////////////////////////////////////////////////////////
//
// All actions
//
////////////////////////////////////////////////////////////////////////////////

export const Action = {
    notify: 'notify',
    auth: {
        check: 'authCheck',
        login: 'login',
        logout: 'logout',
    },
    landlord: {
        loadEntities: 'llEntitiesLoad',
        deleteEntity: 'llEntityDelete',
        newEntity: 'llEntityNew',
        loadAccounts: 'llAccountsLoad',
        newAccount: 'llAccountNew',
        saveAccount: 'llAccountSave',
        deleteAccount: 'llAccountDelete',
        accountToken: 'llAccountToken'
    },
    account: {
        load: 'accountsLoad',
        delete: 'accountDelete',
        add: 'accountAdd',
        save: 'accountSave',
        token: 'accountToken',
        tokenRevoke: 'accountTokenRevoke'
    },
    app: {
        add: 'appAdd',
        load: 'appsLoad',
        delete: 'appDelete',
        save: 'appSave'
    },
    site: {
        add: 'siteAdd',
        load: 'siteLoad',
        delete: 'siteDelete',
        save: 'siteSave'
    },
    kiosk: {
        add: 'kioskAdd',
        load: 'kioskLoad',
        delete: 'kioskDelete',
        save: 'kioskSave'
    },
    dispenser: {
        add: 'dispenserAdd',
        load: 'dispenserLoad',
        delete: 'dispenserDelete',
        save: 'dispenserSave',
    },
    device: {
        add: 'deviceAdd',
        load: 'deviceLoad',
        delete: 'deviceDelete',
        save: 'deviceSave',
        allocate: 'deviceAllocate',
        return: 'deviceReturn'
    },
    entity: {
        loadConfig: 'loadConfig',
        saveConfig: 'saveConfig'
    }
}

