<template>
  <file-input v-bind="$attrs" @change="fileChange" ref="fileUpload" />
</template>


<script>
import Swal from "sweetalert2";

export default {
  name: "FileLoader",
  props: {
    maxSize: Number,
    parseJson: Boolean
  },
  methods: {
    fileChange(files) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.fileRead(reader.result)
      }, false);

      if (files.length > 0) {
        if (files[0].size > this.maxSize) {
          Swal.fire({text: `File ist to big. Only files up to ${this.maxSize} Bytes are allowed.`})
          this.clearFile()
        }
        reader.readAsText(files[0]);
      }
    },

    fileRead(result) {
      if (!this.parseJson) {
        this.$emit('update:content', result)
        return
      }
      try {
        const content = JSON.parse(result)
        this.$emit('update:content', content)
      } catch (e) {
        Swal.fire({text: e.message})
        this.clearFile()
      }
    },

    clearFile() {
      this.$emit('update:content', null)
      this.$refs.fileUpload.value = null;
      this.$refs.fileUpload.files = []
      this.$emit('update:content', null)
    },
  }
}
</script>
