<!-- ######################################################################
#
# Superuser Form
#
###################################################################### -->

<template>
<div>
  <base-header class="pb-6" />
  <b-container fluid class="mt--6 max-width">
    <b-card no-body>

      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form id="entity_form" role="form" @submit.prevent="handleSubmit(onSubmit)">

          <card-header title="Create Account">
            <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
            <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
          </card-header>

          <b-card-body>
            <b-row>
              <b-col md="6">
                <base-input label="Username" name="name"
                            v-model="model.name"
                            :rules="{required: true, apiname: true}" />
              </b-col>
              <b-col md="6">
                <base-input label="E-mail" placeholder="" name="E-mail" v-model="model.email" :rules="{required: true, email: true}"/>
              </b-col>
            </b-row>

            <!-- password fields ####################################################### -->
            <b-row>
              <b-col md="6">
                <base-input label="Password"
                            placeholder=""
                            name="password"
                            v-model="model.password"
                            ref="password"
                            type="password" autocomplete="on"
                            :rules="{required: true, min: 6}" />
              </b-col>
              <b-col md="6">
                <base-input label="Password Confirmation"
                            placeholder=""
                            name="passwordConfirm"
                            v-model="passwordConfirmation"
                            type="password" autocomplete="on"
                            :rules="{required: true, confirmed:'password' }" />
              </b-col>
            </b-row>

            <!-- account type + entity ####################################################### -->
            <b-row>
              <b-col md="6">
                <label class="form-control-label">Account Type</label>
                <b-form-select
                    class="form-control"
                    v-model="model.subtype"
                    :options="accountTypes" />
              </b-col>
              <b-col md="6" v-if="model.subtype!=='sadmin'">
                <label class="form-control-label">Entity</label>
                <b-form-select
                    class="form-control"
                    v-model="model.entity"
                    :rules="{required: model.subtype !== 'sadmin' }"
                    :options="entitiesData" />
              </b-col>
            </b-row>

          </b-card-body>

        </b-form>
      </validation-observer>

    </b-card>
  </b-container>
</div>
</template>

<!-- ###################################################################### -->

<script>
import CardHeader from "@/views/components/CardHeader";
import {mapGetters, mapState} from "vuex";
import {Action} from "@/store/actions";
import {Routes} from "@/router";
import AccountService from "@/services/account.service";

export default {
  name: "SAccountNew",
  // ----------
  data() {
    return {
      passwordConfirmation: '',
      model: {
        name: '',
        email: '',
        password: '',
        subtype: 'admin',
        entity: '',
      },
      accountTypes: AccountService.typesForDropdown(true),
      entitiesData: []
    };
  },
  // ----------
  components: {
    CardHeader,
  },
  // ----------
  methods: {
    onCancel() { this.$router.back() },
    onSubmit() {
      this.$store.dispatch(Action.landlord.newAccount, this.model)
          .then(() => { this.$router.push({name: Routes.saccountList}) })
          .catch(error => { console.error(error) })
    },
    loadEntities() {
      if (this.entities.length === 0) {
        this.$store.dispatch(Action.landlord.loadEntities)
            .then(() => { this.fillEntitiesData() })
            .catch(error => { console.error(error) })
      } else {
        this.fillEntitiesData();
      }
    },
    fillEntitiesData() {
      this.entitiesData = []
      this.entities.forEach((entry) => {
        this.entitiesData.push({ value: entry.name, text: entry.name + " - " + entry.description })
      })
      if (this.entitiesData.length > 0) {
        this.model.entity = this.entities[0].name
      }
    }
  },
  // ----------
  computed: {
    ...mapState({
      isLoading: state => state.general.loading,
      entities: state => state.landlord.entities
    }),
    ...mapGetters(['saccountByName']),
  },
  // ---------
  mounted() {
    this.loadEntities()
  }
}
</script>
