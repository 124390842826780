////////////////////////////////////////////////////////////////////////////////
//
// Store dispenser module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";

const state = {
    // complete dispensers objects
    dispensers: [],
    // sites dispensers like: { value: entry.name, text: entry.name }
    dispensersSelect: [],
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    dispenserByName: state => (name) => state.dispensers.find(dispenser => dispenser.name === name)
};

////////////////////////////////////////////////////////////////////////////////

const actions = {
    async [Action.dispenser.load](context) {
        try {
            const result = await ApiService.dispenser.all()
            if (Array.isArray(result.data.data)) {
                context.commit(Mutation.dispenser.set, result.data.data)
            } else {
                context.commit(Mutation.dispenser.set, [])
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },
    async [Action.dispenser.add](context, document) {
        try {
            const result = await ApiService.dispenser.add({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.dispenser.delete](context, dispenser) {
        try {
            await ApiService.dispenser.del(dispenser.name)
            await this.dispatch(Action.dispenser.load)
            context.commit(Mutation.notify, {message: "Dispenser deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.dispenser.save](context, data) {
        try {
            await ApiService.dispenser.save(data.name, data.data)
            context.commit(Mutation.notify, {message: "Dispenser saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    }

}


////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.dispenser.set](state, dispensers) {
        state.dispensers = dispensers;
        state.dispensersSelect = [{value: null, text: '-'}]
        dispensers.forEach((entry) => {
            state.dispensersSelect.push({ value: entry.name, text: entry.name })
        })
    }
}

////////////////////////////////////////////////////////////////////////////////

const dispenser = {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
export default dispenser