<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <card-header :title="title">
          <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
          <base-button type="success" size="sm" @click="onSave" native-type="submit" :disabled="canDeploy" :loading="isLoading">Return</base-button>
        </card-header>

        <div class="table-title">
          Return to kiosk
        </div>

        <el-table hover class="inset-table" header-row-class-name="thead-light" empty-text="No dispensers found" :data='dispensers'>

          <el-table-column class-name="font-weight-bold" label="Kiosk" sortable prop="kiosk" />

          <el-table-column label="Dispenser" sortable prop="name" />

          <el-table-column label="Capacity" sortable prop="capacity" />

          <el-table-column label="Site" sortable prop="location.site" />

          <el-table-column label="State" sortable prop="state.current" />

          <el-table-column label="Return">
            <div slot-scope="{row}" class="table-actions">

            <b-form-radio
                v-model="selectedDispenser"
                :value="row.kiosk"
                name="dispenser"
            />
            </div>
          </el-table-column>

        </el-table>

      </b-card>
    </b-container>
  </div>
</template>

<script>
import {Routes} from "@/router";
import {mapGetters, mapState} from "vuex";
import {Table, TableColumn} from "element-ui";
import {Action} from "@/store/actions";

export default {
  name: "DeviceDeploy",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      activeDevice: '',
      isLoading: true,
      selectedDispenser: undefined
    };
  },
  computed: {
    canDeploy() {
      return !this.selectedDispenser
    },
    title() {
      return "Return Device " + this.activeDevice
    },
    dispensers() {
      const disp = this.$store.state.dispenser.dispensers.sort((a, b) => {
        if (a.kiosk < b.kiosk) {
          return -1;
        }
        if (a.kiosk > b.kiosk) {
          return 1;
        }
        return 0;
      })
      return disp
    },
    ...mapGetters(['deviceByName']),
    ...mapState({
      //dispensers: state => state.dispenser.dispensers
    })
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.deviceList});
    },
    onSave() {
      this.isLoading = true
      this.$store.dispatch(Action.device.return, {device: this.activeDevice, kiosk: this.selectedDispenser})
        .then(() => {
          this.$router.push({name: Routes.deviceList})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    }
  },
  mounted() {
    this.$store.dispatch(Action.dispenser.load).then(() => { this.isLoading = false })
    const name = this.$route.params.name
    if(!name) { this.$router.back() }
    const device = this.deviceByName(name)
    if(!device) { this.$router.back() }
    this.activeDevice = name;
  },
}
</script>

<style scoped>
.inset-table {
  padding: 30px;
}
.table-title {
  margin-top: 40px;
  margin-left: 30px;
}
</style>