<!-- ######################################################################
#
# Entity form
#
###################################################################### -->

<template>
<div>
  <base-header class="pb-6" />
  <b-container fluid class="mt--6 max-width">
    <b-card no-body>

      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <b-form id="entity_form" role="form" @submit.prevent="handleSubmit(onSubmit)">

          <card-header title="Create Entity">
            <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
            <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
          </card-header>

          <b-card-body>
            <b-row>

              <b-col md="6">
                <base-input label="Name" name="name" v-model="model.name" :rules="{required: true, apiname: true}" />
              </b-col>

              <b-col md="6">
                <base-input label="Description" v-model="model.description" />
              </b-col>

            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>

    </b-card>
  </b-container>
</div>
</template>

<!-- ###################################################################### -->

<script>
import CardHeader from "@/views/components/CardHeader";
import {Action} from "@/store/actions";
import {Routes} from "@/router";

export default {
  name: "EntityForm",
  components: {
    CardHeader
  },
  data() {
    return {
      model: {
        name: '',
        description: '',
      },
      isLoading: false
    };
  },
  methods: {
    onCancel() {
      this.$router.back()
    },
    onSubmit() {
      this.isLoading = true;
      this.$store.dispatch(Action.landlord.newEntity, this.model)
        .then(() => { this.$router.push({name: Routes.entityList}) })
        .catch(error => {
          this.isLoading = false;
          console.error(error);
        })
    },
  }
}
</script>

