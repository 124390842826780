<template>
  <div>
    <base-header class="pb-6">
    </base-header>

    <b-container fluid class="mt--6 max-width">

      <b-card no-body>

        <card-header title="Accounts">
          <base-button size="sm" @click="onNewAccount">Add Account</base-button>
        </card-header>


        <el-table header-row-class-name="thead-light" empty-text="No accounts available" :data='accounts'>

          <el-table-column label="Name" prop="name" min-width="100px" class-name="font-weight-bold" sortable  />

          <el-table-column label="Entity" width="200" prop="entity" min-width="140px" sortable>
            <template v-slot="{row}">
              <span class="font-italic" v-if="isSuperadmin(row)"></span>
              <span v-else>{{row.entity}}</span>
            </template>
          </el-table-column>

          <!-- Role -->
          <el-table-column label="Type" width="140px" prop="subtype">
            <template v-slot="{row}">
              <span :class="[row.subtype]">{{row.subtype}}</span>
            </template>
          </el-table-column>

          <!-- Email -->
          <el-table-column label="E-mail" min-width="140px" prop="email" />

          <!-- ACTIONS -->
          <el-table-column width="140px" align="right">
            <div slot-scope="{row}" class="table-actions">

              <base-button @click.native="onEdit(row)" class="remove btn-link" type="" size="sm" icon>
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button @click.native="handleDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>

            </div>
          </el-table-column>

        </el-table>

      </b-card>

    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Table, TableColumn} from "element-ui";
import CardHeader from "@/views/components/CardHeader";
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapGetters, mapState} from "vuex";
import {confirmDelete} from "@/helper";

export default {
  name: "SAccountList",
  components: {
    CardHeader,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    onNewAccount() {
      this.$router.push({name: Routes.saccountNew})
    },
    onEdit(row) {
      this.$router.push({name: Routes.saccountEdit, params: { name: row.name }})
    },
    loadAccounts() {
      this.$store.dispatch(Action.landlord.loadAccounts)
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.landlord.deleteAccount, row);
        }
      });

    }
  },
  computed: {
    ...mapState({
      accounts: state => state.landlord.accounts,
      isLoading: state => state.general.loading,
    }),
    ...mapGetters([
        'isSuperadmin'
    ])
  },

  mounted() {
    this.loadAccounts();
  }

}
</script>

<!-- ###################################################################### -->

<style>
  span.sadmin {
    color: red;
  }
</style>

