<!-- ######################################################################
#
# Permanent Token inside card
#
###################################################################### -->

<template>
  <div>
    <h3>Permanent Token</h3>
    <b-row class="mb-4">
      <b-col md="12">
        <base-input>
          <textarea class="form-control" id="tokenTextarea" rows="3" readonly></textarea>
        </base-input>
        <base-button size="sm" type="primary" :loading="tokenLoading" @click="onGenerate">Generate</base-button>
        <base-button size="sm" type="secondary" :loading="revokeLoading" @click="onRevoke">Revoke all tokens</base-button>
      </b-col>
    </b-row>
  </div>
</template>

<!-- ###################################################################### -->

<script>
import {Action} from "@/store/actions";
import {random} from "@/helper";

export default {
  name: "PermanentToken",
  data() {
    return {
      tokenLoading: false,
      revokeLoading: false
    }
  },
  // -- props
  props: {
    isSAdmin: {
      default: false,
      type: Boolean,
    },
    entity: {
      default: '',
      type: String,
    },
    account: String
  },
  // ---------------------
  methods:{
    onGenerate() {
      this.tokenLoading = true
      this.generateUser()
    },
    onRevoke() {
      this.revokeLoading = true
      this.revokeUser()
    },

    revokeUser() {
      this.$store.dispatch(Action.account.save, {name: this.account, data: {longtoken_key:random(8)}})
          .catch(error => { console.error(error) })
          .finally(() => {this.revokeLoading = false})
    },

    generateAdmin() {
      this.$store.dispatch(Action.landlord.accountToken, {entity: this.entity, account: this.account})
          .then((token) => { document.getElementById('tokenTextarea').value = token })
          .catch(error => { console.error(error) })
          .finally(() => {this.tokenLoading = false})
    },
    generateUser() {
      this.$store.dispatch(Action.account.token, this.account)
          .then((token) => { document.getElementById('tokenTextarea').value = token })
          .catch(error => { console.error(error) })
          .finally(() => {this.tokenLoading = false})

    }
  }
}
</script>

