////////////////////////////////////////////////////////////////////////////////
//
// Store auth module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";

////////////////////////////////////////////////////////////////////////////////

const state = {
    isLoading: false,
    activeEntity: null
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    isLoading: state => state.isLoading,
    activeEntity: state => state.activeEntity
};

////////////////////////////////////////////////////////////////////////////////

const actions = {
    [Action.notify](context, data) {
        context.commit(Mutation.notify, data)
    }
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.loading](state, isLoading) {
        state.isLoading = isLoading;
    },

    [Mutation.notify](state, data) {
        this._vm.$notify({
            message: data.message,
            timeout: 2000,
            icon: 'ni ni-bell-55',
            type: data.type ?? 'danger'
        });
    },

    [Mutation.notifyApiError](state, error) {
        console.error(error);
        this._vm.$notify({
            message: error.errorInfo.userMessage + ": " + error.errorInfo["developer message"],
            timeout: 4000,
            icon: 'ni ni-bell-55',
            type: 'danger'
        });
    },

    [Mutation.entity](state, entity) {
        state.activeEntity = (entity !== "__landlord__entity") ? entity : null
    }
}

////////////////////////////////////////////////////////////////////////////////

const general = {
    actions: actions,
    getters: getters,
    state: state,
    mutations: mutations,
}
export default general
