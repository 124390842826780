<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">

      <b-card no-body>
        <card-header title="Kiosks">
          <base-button size="sm" @click="onNewKiosk">Add Kiosk</base-button>
        </card-header>

        <el-table header-row-class-name="thead-light" empty-text="No kiosks found" :data='kiosks'>

          <el-table-column class-name="font-weight-bold" label="Name" sortable prop="name" />

          <el-table-column label="Site" sortable prop="location.site"/>

          <el-table-column label="Floor" sortable prop="location.floor"/>

          <el-table-column label="Description" sortable prop="description"/>

          <el-table-column label="State" sortable prop="state.current"/>

          <el-table-column min-width="60px" align="right">
            <div slot-scope="{row}" class="table-actions">

              <base-button @click.native="onEdit(row)" class="remove btn-link" type="" size="sm" icon>
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button @click.native="onDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>

            </div>
          </el-table-column>

        </el-table>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import CardHeader from "@/views/components/CardHeader";
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapState} from "vuex";
import {Table, TableColumn, Tooltip} from "element-ui";
import {confirmDelete} from "@/helper";

export default {
  name: "KioskList",
  components: {
    CardHeader,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    loadKiosk() {
      this.$store.dispatch(Action.kiosk.load)
    },
    onNewKiosk() {
      this.$router.push({name: Routes.kioskNew})
    },
    onEdit(row) {
      this.$router.push({name: Routes.kioskEdit, params: { name: row.name }})
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.kiosk.delete, row);
        }
      });
    }
  },
  computed: {
    ...mapState({
      kiosks: state => state.kiosk.kiosks
    })
  },
  mounted() {
    this.loadKiosk();
  }
}
</script>

<!-- ###################################################################### -->

<style scoped>
</style>