<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">
      <b-card no-body>

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">


            <card-header title="Edit Device">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>
              <b-row>
                <b-col md="6">
                  <base-input label="Device ID" name="name" v-model="form.name" :rules="{required: true, devicename: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Description" name="description" v-model="form.description"/>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <base-input label="Lora DevEUI" name="lora_deveui" v-model="form.lora_deveui" :rules="{lora_deveui: true}" />
                </b-col>

                <b-col md="3">
                  <base-input label="LTE IMSI" name="lte_imsi" v-model="form.lte_imsi" :rules="{lte_imsi: true}" />
                </b-col>

                <b-col md="3">
                  <base-input label="Version ID" name="version" v-model="form.version_id" :rules="{}" />
                </b-col>

                <b-col md="3">
                  <base-input label="Manufacturing Type" name="serial" v-model="form.manufacturing_type" disabled />
                </b-col>

              </b-row>

              <b-row>

                <b-col md="12">
                  <label class="form-control-label">X509 Certificate <span class="font-weight-light">Optional</span></label>
                  <textarea class="form-control mb-2 font-mono" rows="2" v-model="form.cert" readonly></textarea>
                  <file-loader
                      initialLabel="Select Certificate file (.pem, .pub)"
                      accept=".pem,.pub"
                      :maxSize="100000"
                      :content.sync="form.cert" />
                </b-col>

              </b-row>

            </b-card-body>

          </b-form>
        </validation-observer>

      </b-card>
    </b-container>
  </div>
</template>



<script>
import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapGetters} from "vuex";
import {MapServiceDevice} from "@/services/map.service";
import FileLoader from "@/views/components/FileLoader";

export default {
  name: "DeviceEdit",
  components: {
    FileLoader
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        manufacturing_type: 'CC1',
        lora_deveui: '',    // mac_1
        lte_imsi: '',       // mac_2
        version_id: '',
        dispenser: undefined,
        cert: undefined
      },
      activeDevice: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['deviceByName']),
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.deviceList});
    },
    onSubmit() {
      this.isLoading = true
      const model = MapServiceDevice.formToModel(this.form)
      this.$store.dispatch(Action.device.save, {name: this.activeDevice, data: model})
        .then(() => {
          this.$router.push({name: Routes.deviceList})
        }).catch(error => {
          this.isLoading = false
          console.error(error)
        })
    }
  },
  mounted() {
    const name = this.$route.params.name
    if(!name) { this.$router.back() }
    const device = this.deviceByName(name)
    if(!device) { this.$router.back() }
    this.activeDevice = name;
    this.form = MapServiceDevice.modelToForm(device, this.form)
  },
}
</script>

