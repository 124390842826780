<template>
  <b-form-select v-model="localSelected" v-on:change="selectChange" :options="kiosks" />
</template>


<script>
import {Action} from "@/store/actions";
import {mapState} from "vuex";

export default {
  name: "KioskSelect",
  props: {
    selected: String,
  },
  data() {
    return {
      localSelected: ''
    }
  },
  computed: {
    ...mapState({
      kiosks: state => state.kiosk.kiosksSelect,
      isLoading: state => state.site.isLoading
    }),
  },
  methods: {
    selectChange: function(kioskSelected) {
      this.$emit('update:selected', kioskSelected)
    },
    prefill: function() {
      const found = this.kiosks.find(kiosk => kiosk.text === this.localSelected)
      if (this.kiosks.length > 0 && !found) {
        this.localSelected = this.kiosks[0].value
        this.$emit('update:selected', this.localSelected)
      }
    }
  },
  mounted() {
    this.localSelected = this.selected
    this.$store.dispatch(Action.kiosk.load).then(() => {
      this.prefill()
    })
  },
}
</script>

