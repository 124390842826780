<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6 max-width">

      <b-card no-body>
        <card-header title="Dispensers">
          <base-button size="sm" @click="onNew">Add Dispenser</base-button>
        </card-header>

        <el-table header-row-class-name="thead-light" empty-text="No dispensers found" :data='dispensers'>

          <el-table-column class-name="font-weight-bold" label="Name" sortable prop="name" />

          <el-table-column label="Capacity" sortable prop="capacity" />

          <el-table-column label="Kiosk" sortable prop="kiosk" />

          <el-table-column label="Site" sortable prop="location.site" />

          <el-table-column label="State" sortable prop="state.current" />

          <el-table-column min-width="60px" align="right">
            <div slot-scope="{row}" class="table-actions">

              <base-button @click.native="onEdit(row)" class="remove btn-link" type="" size="sm" icon>
                <i class="text-dark fas fa-edit"></i>
              </base-button>

              <base-button @click.native="onDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
                <i class="text-white fas fa-trash"></i>
              </base-button>

            </div>
          </el-table-column>

        </el-table>

      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Routes} from "@/router";
import {Action} from "@/store/actions";
import {mapState} from "vuex";
import {Table, TableColumn, Tooltip} from "element-ui";
import {confirmDelete} from "@/helper";

export default {
  name: "DispenserList",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {
    loadDispenser() {
      this.$store.dispatch(Action.dispenser.load)
    },
    onNew() {
      this.$router.push({name: Routes.dispenserNew})
    },
    onEdit(row) {
      this.$router.push({name: Routes.dispenserEdit, params: { name: row.name }})
    },
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.dispenser.delete, row);
        }
      });
    }
  },
  computed: {
    ...mapState({
      dispensers: state => state.dispenser.dispensers
    })
  },
  mounted() {
    this.loadDispenser();
  }
}
</script>
