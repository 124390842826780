////////////////////////////////////////////////////////////////////////////////
//
// App API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const site = {
    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    all: () => ApiService.getT('site'),

    /**
     * Delete account with name
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    del: (name) => ApiService.deleteT(`site/${name}`),

    /**
     * Add a account with data
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    add: (data) => ApiService.postT('site', data),

    /**
     * Save Site with data
     * @param name dataview resource name
     * @param data { "description": "", "name": "", ... }
     */
    save: (name, data) => {
        const dataCopy = { ...data }
        if (name === dataCopy.name) {
            delete dataCopy.name
        }
        const putData = { operator: { $set: dataCopy } }
        return ApiService.putT(`site/${name}`, putData)
    }
}

export default site;