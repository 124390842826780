<!-- ######################################################################
#
# The Main Navigation Bar
#
###################################################################### -->

<template>
  <div>
    <base-nav containerClasses="container-fluid"
              toggleable="lg"
              type="dark"
              variant="info"
              class="navbar-my"
              menu-classes="justify-content-end">

      <div slot="brand" class="navbar-wrapper mr-auto">
        <b-navbar-brand><img height="40" src="/img/logo-dark.png"></b-navbar-brand>
      </div>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="align-items-center">
          <base-button outline v-if="activeEntity" type="info" @click="onEntityClick">{{activeEntity}}</base-button>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="align-items-center ml-auto">

          <!-- MAIN MENU ###################################################################### -->

          <b-nav-item to="/entity" v-if="rights.landlord">
            <span class="nav-link-inner--text text-white">Entities</span>
          </b-nav-item>

          <b-nav-item to="/saccounts" v-if="rights.landlord">
            <span class="nav-link-inner--text text-white">Accounts</span>
          </b-nav-item>

          <b-nav-item to="/sdevices" v-if="rights.landlord">
            <span class="nav-link-inner--text text-white">Devices</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.accountList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Accounts</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.appList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Dataviews</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.siteList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Sites</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.kioskList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Kiosks</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.dispenserList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Dispensers</span>
          </b-nav-item>

          <b-nav-item :to="{ name: Routes.deviceList }" v-if="!rights.landlord">
            <span class="nav-link-inner--text text-white">Devices</span>
          </b-nav-item>

          <!-- ACCOUNT ###################################################################### -->

          <base-dropdown menu-on-right
                         class="nav-item"
                         tag="li"
                         title-tag="a"
                         title-classes="nav-link pr-0">


            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
              <b-media no-body class="align-items-center">
                <span class="avatar avatar-sm rounded-circle bg-primary">{{usernameShort}}</span>
                <b-media-body class="ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm  font-weight-bold text-white">{{username}}</span>
                </b-media-body>
              </b-media>
            </a>

            <template>

              <b-dropdown-item to="/settings">
                <i class="ni ni-settings-gear-65"></i>
                <span>Settings</span>
              </b-dropdown-item>

              <b-dropdown-divider/>

              <b-dropdown-item href="#!" @click='onLogout'>
                <i class="ni ni-user-run"></i>
                <span>Logout</span>
              </b-dropdown-item>

            </template>

          </base-dropdown>


        </b-navbar-nav>
      </b-collapse>
    </base-nav>
  </div>

</template>

<!-- ###################################################################### -->

<script>
import {Action} from "@/store/actions";
import {mapGetters} from "vuex";
import {mapState} from "vuex";
import {Routes} from "@/router";

export default {
  name: "MainNav",
  data() {
    return {
      Routes: Routes
    };
  },
  methods: {
    onLogout() {
      this.$store.dispatch(Action.auth.logout).then(() => {
        this.$router.push({name: Routes.authLogin});
      });
    },
    onEntityClick() {
      if(this.$route.name !== Routes.entityEdit) {
        this.$router.push({name: Routes.entityEdit});
      }
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'username', 'usernameShort', 'activeEntity']),
    ...mapState({
      rights: state => state.auth.rights,
    }),
  }
}
</script>

<!-- ###################################################################### -->

<style lang="scss">
.active {
  font-weight: bold !important;
}

.navbar-my {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #00000055 !important;
}


</style>