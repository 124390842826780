////////////////////////////////////////////////////////////////////////////////
//
// Store index file
//
////////////////////////////////////////////////////////////////////////////////

import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import general from './modules/general'
import account from "@/store/modules/account";
import landlord from "@/store/modules/landlord";
import app from "@/store/modules/app";
import site from "@/store/modules/site";
import kiosk from "@/store/modules/kiosk";
import dispenser from "@/store/modules/dispenser";
import device from "@/store/modules/device";
import entity from "@/store/modules/entity";

////////////////////////////////////////////////////////////////////////////////

Vue.use(Vuex)

////////////////////////////////////////////////////////////////////////////////

export default new Vuex.Store({
  modules: {
    auth,
    general,
    account,
    landlord,
    app,
    site,
    kiosk,
    dispenser,
    device,
    entity,
  }
})
