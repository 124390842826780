import { extend } from 'vee-validate';
import {validationLatitude, validationLongitude} from "@/helper";

extend('longitude', value => {
    return validationLongitude.test(value);
});

extend('latitude', value => {
    return validationLatitude.test(value);
});

extend('apiname', {
    validate: (value) => {
        return RegExp("^([a-z]|[A-Z]|[_]|[0-9])*$").test(value);
    },
    message: 'The field may contain alpha-numeric characters as well as underscores'
});

extend('devicename', {
    validate: (value) => {
        return RegExp("^([a-f]|[0-9]){12}$").test(value);
    },
    message: 'Has to be a 12 hex lower case digit string (eg "240ac40a2e48")'
});

extend('lora_deveui', {
    validate: (value) => {
        return RegExp("^([a-f]|[0-9]){16}$").test(value);
    },
    message: 'Has to be a 16 hex lower case digit string (eg "240ac40a2e48ab3a")'
});

extend('lte_imsi', {
    validate: (value) => {
        return RegExp("^([0-9]){4,16}$").test(value);
    },
    message: 'Has to be a 4 to 16 decimal digit string (eg "23432")'
});

extend('json', {
    validate: (value) => {
        try {
            JSON.parse(value)
            return true
        } catch (e) {
            return false
        }
    },
    message: 'Is not valid JSON'
});