<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>


<!-- ###################################################################### -->

<script>

const defaultLayout = "LayoutMain";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    }
  },
  created() {
    //console.log(this.$route, this.$route.meta.layout);
  },
  updated() {
    //console.log(this.$route, this.$route.meta.layout);
  }
}
</script>
