////////////////////////////////////////////////////////////////////////////////
//
// Store landlord module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const state = {
    entities: [],
    accounts: [],
}

const getters = {
    getEntities: state => state.entities,
    saccountByName: state => (name) => {
        return state.accounts.find(account => account.name === name)
    }
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.landlord.setEntities](state, entities) {
        state.entities = entities;
    },
    [Mutation.landlord.setAccounts](state, accounts) {
        state.accounts = accounts;
    }
}

////////////////////////////////////////////////////////////////////////////////

const actions = {

    async [Action.landlord.deleteEntity](context, entity) {
        try {
            const result = await ApiService.landlord.deleteEntity(entity.name)
            await this.dispatch(Action.landlord.loadEntities)
            context.commit(Mutation.notify, {message: result.data.data.result, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
        }
    },

    async [Action.landlord.loadEntities](context) {
        try {
            const entities = await ApiService.landlord.getEntities()
            console.log(entities)
            context.commit(Mutation.landlord.setEntities, entities.data.data)
        } catch (error) {
            console.error(error);
            context.commit(Mutation.notify, {message: 'Error loading data'});
        }
    },

    async [Action.landlord.newEntity](context, data) {
        try {
            const result = await ApiService.landlord.newEntity(data)
            context.commit(Mutation.notify, {message: result.data.data.result, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notify, {message: error.errorInfo.userMessage})
            return Promise.reject(error)
        }
    },

    async [Action.landlord.loadAccounts](context) {
        try {
            const result = await ApiService.landlord.getAccounts()
            context.commit(Mutation.landlord.setAccounts, result.data.data)
        } catch (error) {
            context.commit(Mutation.notify, {message: error.errorInfo.userMessage})
            return Promise.reject(error)
        }
    },
    
    async [Action.landlord.newAccount](context, document) {
        try {
            if(document.subtype === 'sadmin') {
                delete document.entity;
            }
            const result = await ApiService.landlord.newAccount({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.landlord.deleteAccount](context, account) {
        try {
            await ApiService.landlord.deleteAccount(account.name)
            await this.dispatch(Action.landlord.loadAccounts)
            context.commit(Mutation.notify, {message: "Account deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.landlord.saveAccount](context, data) {
        try {
            await ApiService.landlord.saveAccount(data.name, data.data);
            context.commit(Mutation.notify, {message: "Account saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    /**
     * Get token
     * @param data: {entity: '', account: ''}
     */
    async [Action.landlord.accountToken](context, data) {
        try {
            const result = await ApiService.landlord.getToken(data.entity, data.account)
            return Promise.resolve(result.data.data.token)
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

}

////////////////////////////////////////////////////////////////////////////////

const landlord = {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}
export default landlord
