<template>
<div>
  <base-header class="pb-6"/>

  <b-container fluid class="mt--6 max-width">

    <b-card no-body>

      <card-header title="Dataviews">
        <base-button size="sm" @click="onNewApp">Add Dataview</base-button>
      </card-header>

      <el-table header-row-class-name="thead-light" empty-text="No dataviews available" :data='apps'>

        <el-table-column class-name="font-weight-bold" label="Name" sortable prop="name" />
        <el-table-column label="Owner" prop="owner" />
        <el-table-column label="Version" prop="version" />
        <el-table-column :render-header="renderAuthorizedAccountsHeader"><div slot-scope="{row}">
          {{ row.authorized_accounts.length }}
        </div></el-table-column>
        <el-table-column label="Description" sortable prop="description"/>

        <el-table-column width="130px" label="JSON">
          <div slot-scope="{row}" class="table-actions">
            <base-button size="sm" @click="onDownload(row)">Download</base-button>
          </div>
        </el-table-column>

        <el-table-column width="180px" align="right">
          <div slot-scope="{row}" class="table-actions">

            <base-button @click.native="onAppBuilder(row)" class="remove btn-link" type="" size="sm" icon>
              <i class="text-dark fas fa-image"></i>
            </base-button>

            <base-button @click.native="onEdit(row)" class="remove btn-link" type="" size="sm" icon>
              <i class="text-dark fas fa-edit"></i>
            </base-button>

            <base-button @click.native="onDelete(row)" class="remove btn-link" type="danger" size="sm" icon>
              <i class="text-white fas fa-trash"></i>
            </base-button>

          </div>
        </el-table-column>


      </el-table>

    </b-card>

  </b-container>
</div>
</template>

<!-- ###################################################################### -->

<script>
import {Action} from "@/store/actions";
import {mapState} from "vuex";
import {Table, TableColumn} from "element-ui";
import CardHeader from "@/views/components/CardHeader";
import {Routes} from "@/router";
import {confirmDelete, download} from "@/helper";

export default {
  name: "AppList",
  components: {
    CardHeader,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    ...mapState({
      apps: state => state.app.apps,
      isLoading: state => state.general.loading,
    }),
  },
  methods: {
    onDelete(row) {
      confirmDelete().then(result => {
        if (result.value) {
          this.$store.dispatch(Action.app.delete, row);
        }
      });
    },
    onNewApp() {
      this.$router.push({name: Routes.appNew})
    },
    onEdit(row) {
      this.$router.push({name: Routes.appEdit, params: { name: row.name }});
    },
    onAppBuilder(row) {
      this.$router.push({name: Routes.appBuilder, params: { name: row.name }});
    },
    onDownload(row) {
      download(`${row.name}.json`, JSON.stringify(row.content))
    },
    renderAuthorizedAccountsHeader() {
      return (
        <span>Authorized<br/>Accounts</span>
      )
    }
  },
  mounted() {
    this.$store.dispatch(Action.app.load)
  }
}
</script>
