import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from './store'
import "./vee-validate";

import { configure } from 'vee-validate';
import { TokenService } from "./services/token.service";

import ApiService from "./services/api.service";

import '@/assets/sass/argon.scss';
import '@/assets/sass/_custom.scss';
import '@/assets/css/nucleo/css/nucleo.css';

import Notifications from '@/views/components/theme/notification';
import GlobalComponents from './views/components/globalComponents';
import clickOutside from "@/views/directives/click-ouside";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

////////////////////////////////////////////////////////////////////////////////

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

////////////////////////////////////////////////////////////////////////////////

Vue.config.productionTip = false

Vue.use(GlobalComponents);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.directive('click-outside', clickOutside);

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
  }
})

// API init
ApiService.init();
if (TokenService.token()) {
  ApiService.setHeader();
}

////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line no-unused-vars
var vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')