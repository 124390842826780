<template>
  <div>
    <base-header class="pb-6" />

    <b-container fluid class="mt--6 max-width">
      <b-card no-body>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">


            <card-header title="Create Account">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Create</base-button>
            </card-header>

            <b-card-body>

              <b-row>
                <b-col md="6">
                  <base-input label="Username" name="name" v-model="model.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="E-mail" name="E-mail" v-model="model.email" :rules="{required: true, email: true}"/>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input label="Password" name="password"
                              v-model="model.password"
                              ref="password"
                              type="password" autocomplete="on"
                              :rules="{required: true, min: 6}" />
                </b-col>
                <b-col md="6">
                  <base-input label="Password Confirmation" name="passwordConfirm"
                              v-model="passwordConfirmation"
                              type="password" autocomplete="on"
                              :rules="{required: true, confirmed:'password' }" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <label class="form-control-label">Account Type</label>
                  <b-form-select class="form-control" v-model="model.subtype" :options="accountTypes" />
                </b-col>
              </b-row>

            </b-card-body>
          </b-form>
        </validation-observer>
      </b-card>
    </b-container>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Routes} from "@/router";
import {Action} from "@/store/actions";
import AccountService from "@/services/account.service";

export default {
  name: "AccountNew",
  data() {
    return {
      model: {
        name: '',
        email: '',
        password: '',
        subtype: 'admin',
      },
      passwordConfirmation: '',
      accountTypes: AccountService.typesForDropdown(),
      isLoading: false,
    };
  },
  methods: {
    onCancel() {
      this.$router.push({name: Routes.accountList});
    },
    onSubmit() {
      this.isLoading = true
      this.$store.dispatch(Action.account.add, this.model)
          .then(() => { this.$router.push({name: Routes.accountList}) })
          .catch(error => {
            console.error(error)
            this.isLoading = false
          })
    }
  },
}

</script>
