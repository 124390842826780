<!-- ######################################################################
#
# Main App Layout
#
###################################################################### -->

<template>
  <div class="wrapper">
    <notifications></notifications>

    <div class="main-content">
      <main-nav />

      <slot/>

    </div>

  </div>
</template>

<!-- ###################################################################### -->

<script>

import MainNav from "@/views/components/MainNav";

export default {
  name: "LayoutMain",
  components: {
    MainNav,
  }
}
</script>
