////////////////////////////////////////////////////////////////////////////////
//
// The API access
//
////////////////////////////////////////////////////////////////////////////////

// {
//      "result" : "true|false", 	            // always present : request was successful?
//      "httpResponseCode" : "200|304|400….",	// if parameter ‘suppress_response_codes=true’ in request
//      "errorInfo" : {	    // If isOk=false
//          "developerMessage”:”Verbose, plain language description of the problem for the app developer with hints about how to fix it.",
//          "userMessage":"Pass this message on to the app user if needed.",
//          "errorCode" : 12345,
//          "moreInfo": // link to be defined
//      },
//      "data":{
//          // returned data object if present
//      },
//      "metadata": {
//          "numObjects" : "total number of objects that could be returned",
//          "offset" : "as set in request",
//          "limit" : "as set in request"
//      }
// }

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { TokenService } from "@/services/token.service";

import auth from "@/services/api/auth";
import account from "@/services/api/account";
import landlord from "@/services/api/landlord";
import app from "@/services/api/app";
import site from "@/services/api/site";
import kiosk from "@/services/api/kiosk";
import dispenser from "@/services/api/dispenser";
import device from "@/services/api/device";
import kioskManager from "@/services/api/kioskManager"
import entity from "@/services/api/entity";

import store from "@/store"
import {Mutation} from "@/store/mutations";
import router from "@/router";

////////////////////////////////////////////////////////////////////////////////

// the placeholder below will be replaced with the real url by a shell scriot
//export const BASE_URL = 'https://@@PLATFORM_API_HOST@@';
export const BASE_URL = '/api';
export const API_URL = `${BASE_URL}/ife/rest/v1`;
export const API_URL_KIOSK_MGR = `${BASE_URL}/kmgr/rest/v1`;

////////////////////////////////////////////////////////////////////////////////

const isSilent = (config={}) => {
    return Object.prototype.hasOwnProperty.call(config, 'silent') && config.silent
}

const requestHandler = (request) => {
    if (!isSilent(request)) {
        store.commit(Mutation.loading, true)
    }
    return request
}

const errorHandler = (error) => {
    if (!isSilent(error)) {
        store.commit(Mutation.loading, false)
        if (error.response === undefined) {
            store.commit(Mutation.notify, { message:'Network error' })
            return Promise.reject(undefined)
        } else if (error.response.status === 401) {
            store.commit(Mutation.auth.delete)
            router.push("/auth/login")
            Promise.reject("Error in authentication. Logging out.")
        }
    }
    return Promise.reject(error.response.data)
}

const successHandler = (response) => {
    if (!isSilent(response)) {
        store.commit(Mutation.loading, false)
    }
    return response
}


axios.interceptors.request.use(
    request => requestHandler(request),
)
axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

////////////////////////////////////////////////////////////////////////////////
//
// The 'T' stands for Tenant. getT uses the tentant API with the current set
// entity in global store
//
////////////////////////////////////////////////////////////////////////////////

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        axios.defaults.baseURL = API_URL;
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.token()}`;
    },
    removeHeader() {
        axios.defaults.headers.common = {}
    },
    get(resource, config) {
        return axios.get(`${resource}`, config)
    },
    getT(resource) {
        return axios.get(`${this.tenantPrefix()}${resource}`)
    },
    post(resource, data) {
        return axios.post(`${resource}`, data)
    },
    postT(resource, data) {
        return axios.post(`${this.tenantPrefix()}${resource}`, data)
    },

    postKioskManager(resource, data) {
        return axios({
            baseURL: `${API_URL_KIOSK_MGR}/kiosk/${store.getters.activeEntity}/${resource}`,
            method: 'post',
            data: data
        })
    },

    put(resource, params) {
        return axios.put(`${resource}`, params);
    },
    putT(resource, params) {
        return axios.put(`${this.tenantPrefix()}${resource}`, params);
    },
    delete(resource) {
        return axios.delete(resource)
    },
    deleteT(resource) {
        return axios.delete(`${this.tenantPrefix()}${resource}`)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)
    },

    /**
     * Returns the tenant prefix
     * @returns {string|string}
     */
    tenantPrefix() {
        const tenant = store.getters.activeEntity;
        return tenant ? `tenant/${tenant}/` : '';
    },


    // -- modules
    auth: auth,
    account: account,
    landlord: landlord,
    app: app,
    site: site,
    kiosk: kiosk,
    entity: entity,
    dispenser: dispenser,
    device: device,
    kioskManager: kioskManager
}

////////////////////////////////////////////////////////////////////////////////

export default ApiService;