<!-- ######################################################################
#
# Change Password Modal Dialog
#
###################################################################### -->

<template>
  <modal :show="show"
         size="sm"
         body-classes="p-0" >
    <template slot="header"><h3 class="mb-0">Change password</h3></template>
    <card>
      <validation-observer v-slot="{ handleSubmit }">
        <b-form id="form_saccount_password" role="form" @submit.prevent="handleSubmit(onSubmit)">
          <base-input label="Password"
                      vid="passwordModal"
                      name="password"
                      v-model="model.password"
                      type="password" autocomplete="on"
                      :rules="{required: true, min: 6}" />

          <base-input label="Password Confirmation"
                      name="Password Confirmation"
                      v-model="passwordConfirmation"
                      type="password" autocomplete="on"
                      :rules="{required: true, confirmed:'passwordModal' }" />
        </b-form>
      </validation-observer>
    </card>
    <template slot="footer">
      <base-button type="link" @click="handleClose">Cancel</base-button>
      <base-button native-type="submit" form="form_saccount_password" type="primary">Save</base-button>
    </template>
  </modal>
</template>

<!-- ###################################################################### -->

<script>
import Modal from "@/views/components/theme/Modal";

export default {
  name: "ModalPassword",
  data() {
    return {
      model: {
        password: ''
      },
      passwordConfirmation: ''
    };
  },
  props: {
    onSave: Function,
    show: Boolean
  },
  comments: {
    Modal
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false);
    },
    onSubmit() {
      this.onSave(this.model.password)
    }
  },
  mounted() {
    this.password = '';
    this.passwordConfirmation = '';
  }

}
</script>
