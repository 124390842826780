////////////////////////////////////////////////////////////////////////////////
//
// Landlord API module
//
////////////////////////////////////////////////////////////////////////////////

import ApiService from "@/services/api.service";

////////////////////////////////////////////////////////////////////////////////

const landlord = {

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Accounts
    //
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all accounts
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAccounts: () =>ApiService.get('account'),

    /**
     * Create a new account
     * @param document
     * @returns {Promise<AxiosResponse<any>>}
     */
    newAccount: (document) => ApiService.post('account', document),

    /**
     * Save a account
     * @param name name of account
     * @param data the account data
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveAccount(name, data) {
        if(data.password === "") { delete data.password }
        if(data.entity === "") { delete data.entity }
        const putData = {
            operator: {
                $set: data
            }
        }
        return ApiService.put(`account/${name}`, putData)
    },

    /**
     * Delete account
     * @param name name of account
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteAccount: (name) => ApiService.delete(`account/${name}`),

    /**
     * Get token for account
     * @param entity
     * @param name
     */
    getToken: (entity, name) => ApiService.get(`tenant/${entity}/${name}/permanent-token`),

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Entities
    //
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all entities
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEntities: () => {
        return ApiService.get('tenant')
    },

    /**
     * Generate a entity
     * @param data {name: '', description: ''}
     * @returns {Promise<AxiosResponse<any>>}
     */
    newEntity: (data) => {
        const postData = { document: {} }
        postData.document[data.name] = {
            name: data.name,
            description: data.description,
        }
        return ApiService.post('tenant', postData)
    },

    /**
     * Delete a entity
     * @param name entity name
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteEntity: (name) => ApiService.delete(`tenant/${name}`)
}

export default landlord;