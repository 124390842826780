import { render, staticRenderFns } from "./KioskList.vue?vue&type=template&id=946e6a90&scoped=true"
import script from "./KioskList.vue?vue&type=script&lang=js"
export * from "./KioskList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946e6a90",
  null
  
)

export default component.exports