////////////////////////////////////////////////////////////////////////////////
//
// Store app module
//
////////////////////////////////////////////////////////////////////////////////

import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";
import {Mutation} from "@/store/mutations";

////////////////////////////////////////////////////////////////////////////////

const state = {
    apps: []
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    appByName: state => (name) => state.apps.find(app => app.name === name)
};


////////////////////////////////////////////////////////////////////////////////

const actions = {

    async [Action.app.load](context) {
        try {
            const result = await ApiService.app.all()
            if (Array.isArray(result.data.data)) {
                context.commit(Mutation.app.set, result.data.data)
            } else {
                context.commit(Mutation.app.set, [])
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.app.add](context, model) {
        try {
            const result = await ApiService.app.add(model)
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.app.delete](context, app) {
        try {
            await ApiService.app.del(app.name)
            await this.dispatch(Action.app.load)
            context.commit(Mutation.notify, {message: "Dataview deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.app.save](context, data) {
        try {
            await ApiService.app.save(data.name, data.model)
            context.commit(Mutation.notify, {message: "Dataview saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },


}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.app.set](state, apps) {
        state.apps = apps;
    }
}

////////////////////////////////////////////////////////////////////////////////

const app = {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
export default app

