////////////////////////////////////////////////////////////////////////////////
//
// All Mutations
//
////////////////////////////////////////////////////////////////////////////////

export const Mutation = {
    loading : "setLoading",
    notify : "notify",
    notifyApiError: "notifyApiError",
    entity: "entity",

    auth: {
        delete: 'authDelete',
        set: 'authSet',
        error: 'authError',
        setAccount: 'setAccount',
    },
    landlord: {
        setEntities: 'llSetEntities',
        setAccounts: 'llSetAccounts'
    },
    account: {
        set: 'accountSet'
    },
    app: {
        set: 'appsSet'
    },
    site: {
        set: 'siteSet'
    },
    kiosk: {
        set: 'kioskSet'
    },
    dispenser: {
        set: 'dispenserSet'
    },
    device: {
        set: 'deviceSet'
    },
    entityConfig: {
        set: 'entityConfigSet'
    }
}
