<template>
  <div>
    <base-header class="pb-6" />

    <b-container fluid class="mt--6 max-width">
      <b-card no-body>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">


            <card-header title="Account Data">
              <base-button type="white" size="sm" @click="onCancel">Cancel</base-button>
              <base-button type="success" size="sm" native-type="submit" :loading="isLoading">Save</base-button>
            </card-header>

            <b-card-body>

              <b-row>
                <b-col md="6">
                  <base-input label="Username" name="name" v-model="model.name" :rules="{required: true, apiname: true}" />
                </b-col>
                <b-col md="6">
                  <base-input label="E-mail" name="E-mail" v-model="model.email" :rules="{required: true, email: true}"/>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <label class="form-control-label">Account Type</label>
                  <b-form-select class="form-control" v-model="model.subtype" :options="accountTypes" />
                </b-col>
                <b-col md="6">
                  <label class="form-control-label">Password</label>
                  <base-button type="secondary" block @click="showPassword=true">Change Password</base-button>
                </b-col>
              </b-row>

            </b-card-body>
          </b-form>
        </validation-observer>

        <hr>

        <b-card-header>
          <PermanentToken :account="editAccount"/>
        </b-card-header>
      </b-card>


    </b-container>

    <modal-password :show.sync="showPassword" :on-save="onSubmitPassword"/>

  </div>
</template>

<!-- ###################################################################### -->

<script>

import {Routes} from "@/router";
import ModalPassword from "@/views/components/ModalPassword";
import AccountService from "@/services/account.service";
import {mapGetters} from "vuex";
import {Action} from "@/store/actions";
import PermanentToken from "@/views/components/PermanentToken";

export default {
  name: "AccountEdit",
  components: {
    PermanentToken,
    ModalPassword,
  },
  // ---------------------
  data() {
    return {
      model: {
        name: '',
        email: '',
        subtype: 'admin',
      },
      editAccount: null,
      showPassword: false,
      passwordConfirmation: '',
      accountTypes: AccountService.typesForDropdown(),
      tokenLoading: false,
      isLoading: false
    };
  },
  // ---------------------
  computed: {
    ...mapGetters(['accountByName']),
  },
  // ---------------------
  methods: {
    onCancel() {
      this.$router.push({name: Routes.accountList})
    },
    onSubmit() {
      this.isLoading = true
      this.$store.dispatch(Action.account.save, {name: this.editAccount, data: this.model})
          .then(() => { this.$router.push({name: Routes.accountList}) })
          .catch(error => {
            console.error(error)
            this.isLoading = false
          })
    },
    onSubmitPassword(newPassword) {
      this.$store.dispatch(Action.account.save, {name: this.editAccount, data: { password: newPassword }} )
          .then(() => { this.showPassword = false })
          .catch(error => { console.error(error) })

    },
    onGenerate() {
      this.tokenLoading = true
      this.$store.dispatch(Action.account.token, this.editAccount)
        .then((token) => { document.getElementById('tokenTextarea').value = token })
        .catch(error => { console.error(error) })
        .finally(() => {this.tokenLoading = false})
    },
  },
  // ---------------------
  mounted() {
    const name = this.$route.params.name
    if(!name) { this.$router.back() }
    const account = this.accountByName(name)
    if(!account) { this.$router.back() }
    this.editAccount = name;
    this.model.name = account.name
    this.model.email = account.email
    this.model.subtype = account.subtype
  },
}

</script>


<style scoped>
.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
</style>
