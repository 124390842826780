<template>
  <b-form-select v-model="localSelected" v-on:change="selectChange" :options="accountsForSelect" />
</template>

<script>
import {mapState} from "vuex";
import {Action} from "@/store/actions";

export default {
  name: "AccountSelect",
  props: {
    selected: String,
    filterSubtype: Array,
    addEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localSelected: ''
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.account.accounts,
    }),
    accountsForSelect: function() {
      const select = [];
      if (this.addEmpty) {
        select.push({value: null, text: '-'})
      }
      this.accounts.forEach((account) => {
        if ((this.filterSubtype && this.filterSubtype.find(role => account.subtype === role)) || !this.filterSubtype) {
          select.push({value: account.name, text: account.name})
        }
      })
      return select
    }
  },
  methods: {
    selectChange: function(selected) {
      this.$emit('update:selected', selected)
    },
    prefill: function() {
      const found = this.accountsForSelect.find(account => account.text === this.localSelected)
      if (this.accountsForSelect.length > 0 && !found) {
        this.localSelected = this.accountsForSelect[0].value
        this.$emit('update:selected', this.localSelected)
      }
    }
  },
  mounted() {
    this.localSelected = this.selected
    this.$store.dispatch(Action.account.load).then(() => {
      this.prefill()
    })
  }
}
</script>
