<!-- ######################################################################
#
# App Builder base component
#
###################################################################### -->

<template>
  <div id="builder-container" class="container">
    <div class="row">
      <div id="panel-left" class="col-3 full-height">
      </div>
      <div id="panel-center" class="col-6 full-height">

        <div id="device-container">
          <div id="device-grid">

            <grid-layout
                :autoSize="false"
                :layout.sync="layout"
                :margin="[0,0]"
                :preventCollision="true"
                :col-num="3"
                :maxRows="4"
                :row-height="125"
                :is-draggable="true"
                :is-resizable="true"
                :is-mirrored="false"
                :vertical-compact="false"
                :use-css-transforms="true"
            >

              <grid-item v-for="item in layout"
                         :x="item.x"
                         :y="item.y"
                         :w="item.w"
                         :h="item.h"
                         :i="item.i"
                         :key="item.i">
                {{item.i}}
              </grid-item>

            </grid-layout>


          </div>
        </div>
        
      </div>
      <div id="panel-right" class="col-3 full-height">
      </div>
    </div>
  </div>
</template>

<!-- ###################################################################### -->

<script>

import { GridLayout, GridItem } from "vue-grid-layout"

export default {
  name: "AppBuilder",
  components: {
    GridLayout,
    GridItem
  },
  data() {
    return {
      layout: [
        {"x":0,"y":0,"w":2,"h":2,"i":"0"},
        {"x":2,"y":0,"w":1,"h":3,"i":"1"},
      ],
    }
  }
}
</script>

<!-- ###################################################################### -->

<style scoped>

#panel-left {
  overflow: auto;
  border-right: 1px #9797977F solid;
  box-shadow: 2px 0 20px 0 #97979726;
}

#panel-center {

}

#panel-right {
  overflow: auto;
  border-left: 1px #9797977F solid;
  box-shadow: -2px 0 20px 0 #97979726;
}

.full-height {
  height: calc(100vh - 75px);
}

#builder-container {
  margin-top: 75px;
  max-width: 10000px;
  min-width: 1100px;
}

#device-container {
  overflow: auto;
  width: 525px;
  height: 837px;
  margin: 0 auto;
  background-image: url("/img/device.svg");
}

#device-grid {
  background-color: #2dce8950;
  position: relative;
  width: 400px;
  height: 500px;
  margin: 164px auto;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}



</style>