<!-- ######################################################################
#
# Card header with slot for right buttons
#
###################################################################### -->

<template>
  <b-card-header class="border-1">
    <b-row>
      <b-col class="ms-auto">
        <h3 class="mb-0">{{title}}</h3>
      </b-col>
      <b-col class="text-right">
        <slot/>
      </b-col>
    </b-row>
  </b-card-header>
</template>

<!-- ###################################################################### -->

<script>
export default {
  name: "CardHeader",
  props: {
    title: String
  }
}
</script>

