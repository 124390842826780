////////////////////////////////////////////////////////////////////////////////
//
// Store kiosk module
//
////////////////////////////////////////////////////////////////////////////////

import {Mutation} from "@/store/mutations";
import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";

const state = {
    // complete kiosk objects
    kiosks: [],
    // sites values like: { value: entry.name, text: entry.name }
    kiosksSelect: [],
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    kioskByName: state => (name) => state.kiosks.find(kiosk => kiosk.name === name)
};

////////////////////////////////////////////////////////////////////////////////

const actions = {
    async [Action.kiosk.load](context) {
        try {
            const result = await ApiService.kiosk.all()
            if (Array.isArray(result.data.data)) {
                context.commit(Mutation.kiosk.set, result.data.data)
            } else {
                context.commit(Mutation.kiosk.set, [])
            }
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.kiosk.add](context, document) {
        try {
            const result = await ApiService.kiosk.add({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.kiosk.save](context, data) {
        try {
            await ApiService.kiosk.save(data.name, data.data)
            context.commit(Mutation.notify, {message: "Kiosk saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.kiosk.delete](context, kiosk) {
        try {
            await ApiService.kiosk.del(kiosk.name)
            await this.dispatch(Action.kiosk.load)
            context.commit(Mutation.notify, {message: "Kiosk deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },
}

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.kiosk.set](state, kiosks) {
        state.kiosks = kiosks;
        state.kiosksSelect = [{value: null, text: '-'}]
        kiosks.forEach((entry) => {
            state.kiosksSelect.push({ value: entry.name, text: entry.name })
        })
    }
}

////////////////////////////////////////////////////////////////////////////////

const kiosk = {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
export default kiosk