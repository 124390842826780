////////////////////////////////////////////////////////////////////////////////
//
// Store account module
//
////////////////////////////////////////////////////////////////////////////////

import {Action} from "@/store/actions";
import ApiService from "@/services/api.service";
import {Mutation} from "@/store/mutations";

////////////////////////////////////////////////////////////////////////////////

const state = {
    accounts: []
}

////////////////////////////////////////////////////////////////////////////////

const getters = {
    accountByName: state => (name) => {
        return state.accounts.find(account => account.name === name)
    },
    isSuperadmin: state => (account) => {
        state === state;
        return account.entity === '__landlord__entity'
    }
};

////////////////////////////////////////////////////////////////////////////////

const mutations = {
    [Mutation.account.set](state, accounts) {
        state.accounts = accounts;
    }
}

////////////////////////////////////////////////////////////////////////////////

const actions = {

    async [Action.account.load](context) {
        try {
            const result = await ApiService.account.all()
            context.commit(Mutation.account.set, result.data.data)
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.account.delete](context, account) {
        try {
            await ApiService.account.del(account.name)
            await this.dispatch(Action.account.load)
            context.commit(Mutation.notify, {message: "Account deleted", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.account.add](context, document) {
        try {
            const result = await ApiService.account.add({document})
            context.commit(Mutation.notify, {message: result.data.data.status, type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.account.save](context, data) {
        try {
            await ApiService.account.save(data.name, data.data)
            context.commit(Mutation.notify, {message: "Account saved", type: 'success'})
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },

    async [Action.account.token](context, username) {
        try {
            const result = await ApiService.account.token(username)
            return Promise.resolve(result.data.data.token)
        } catch (error) {
            context.commit(Mutation.notifyApiError, error)
            return Promise.reject(error)
        }
    },
}

////////////////////////////////////////////////////////////////////////////////

const account = {
    getters: getters,
    state: state,
    actions: actions,
    mutations: mutations,
}
export default account
